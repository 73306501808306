import styles from './model-viewer-rotation-description.module.scss';

interface ModelViewerRotationDescriptionProps {
    show: boolean;
}

export function ModelViewerRotationDescription({ show }: ModelViewerRotationDescriptionProps) {
    return show ? (
        <div className={styles.description}>
            <ul>
                <li>When the model is rotated, it's checked to ensure it still fits within the envelope.</li>
                <li>After rotating the model, it will align itself based on the center of mass rule.</li>
            </ul>
        </div>
    ) : null;
}

import cn from 'classnames';
import { forwardRef } from 'react';
import { useAppSelector } from '@app/hooks';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { Fade } from '@components/animations';
import { FilesList } from '@components/file-field';
import { Label } from '@components/label';
import { Price } from '@components/price';
import { TbdLabel } from '@components/tbd';
import { Purchase } from '@types';
import { ModelCell } from '@modules/models';
import { PurchaseSpecification } from '@modules/product';
import { selectOrderPageExactTriggersTbd } from '@modules/order-page';
import { partsId } from '../../helpers';

import styles from './order-page-parts.module.scss';

interface OrderPagePartProps {
    purchase: Purchase;
}

export const OrderPagePart = forwardRef<HTMLTableRowElement, OrderPagePartProps>(function UserOrderPart(
    { purchase, ...rest },
    ref,
) {
    const hasDrawings = Boolean(purchase.drawing_files.length);
    const triggersTbd = useAppSelector(state => selectOrderPageExactTriggersTbd(purchase.id)(state));

    const { t } = useTranslation();
    return (
        <>
            <Fade as="tr" in className={styles.row} mountOnEnter unmountOnExit appear {...rest}>
                <td className={styles.cell}>
                    <ModelCell label={purchase.product?.title} thumb={purchase.product?.thumb_120x120} />
                </td>

                <td className={styles.cell}>
                    <Typography type="body-2" component={'div'}>
                        {purchase.material_title}
                    </Typography>
                </td>

                <td className={cn(styles.cell, styles.specification)}>
                    {purchase.config_formatted ? (
                        <PurchaseSpecification fields={purchase.config_formatted_json} />
                    ) : (
                        '-'
                    )}
                </td>

                <td className={styles.cell}>
                    <Typography type="body-2" component={'div'} className="rmd-typography--center">
                        {parseFloat(purchase.discount_percents) + '%'}
                    </Typography>
                </td>

                <td className={styles.cell}>
                    <Typography type="body-2" component={'div'} className="rmd-typography--center">
                        {purchase.amount}
                    </Typography>
                </td>

                <td className={cn(styles.cell, 'rmd-typography--right')}>
                    {triggersTbd.length ? (
                        <TbdLabel />
                    ) : (
                        <Typography type="body-2" component={'div'} className="rmd-typography--right">
                            <Price>{purchase.models_price}</Price>
                        </Typography>
                    )}
                </td>
            </Fade>

            {hasDrawings && (
                <Fade as="tr" in className={styles.row} mountOnEnter unmountOnExit appear {...rest}>
                    <td colSpan={6} className={styles.drawings}>
                        {/*todo wait for order_products*/}
                        <Label>{t('subtitles.drawings', 'Technical drawings')}</Label>
                        <FilesList
                            expandToggleId={partsId('files_expander', purchase.id)}
                            fileObjects={purchase.drawing_files}
                            expandedByDefault={false}
                        />
                    </td>
                </Fade>
            )}
        </>
    );
});

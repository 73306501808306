import uniq from 'lodash/uniq';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createPersistReducer, PersistReducerConfigs } from '@app/persist';
import { ObjectModelModel } from '@models';
import {
    ObjectModel,
    UpdateModelPayload,
    UpdateNonCadModelPayload,
    ObjectModelStatus,
    RelatedObjectModelData,
    TransformedModelData,
    TransformModelPayload,
    ScaleModelPayload,
    RotateModelPayload,
    InvalidateLevel,
    // ObjectModel,
} from '@types';
import { composeTransformedModelPollingId } from '@utils';
import { appActions } from '../app';

export interface ModelsReducerState {
    selectedModels: number[]; // list of parent-model's ids
    data: Record<string, ObjectModelModel>;
    transformedModelsData: Record<string, TransformedModelData>; // [parent model ID + units|rotation] : data
    relatedModelsData: Record<string, RelatedObjectModelData>; // model ID : data
    isPollingActive: boolean;
    isTransformedModelPollingActive: boolean;
    isLoading: boolean;
    isUpdating: boolean;
    modelsVerified: boolean;
}

const initialState: ModelsReducerState = {
    selectedModels: [],
    data: {},
    transformedModelsData: {},
    relatedModelsData: {},
    modelsVerified: false,
    isPollingActive: false,
    isTransformedModelPollingActive: false,
    isLoading: false,
    isUpdating: false,
};

const createTransformModelReducer = () => ({
    reducer: (
        state: ModelsReducerState,
        { payload: { key, data } }: PayloadAction<{ key: string; data: TransformedModelData }>,
    ) => {
        state.transformedModelsData[key] = data;
    },
    prepare: (payload: any) => ({
        payload: {
            key: composeTransformedModelPollingId(payload),
            data: { ...payload, ts: Date.now(), deleted: false },
        },
    }),
});

// todo split with reset https://github.com/gdagundaridze/rtk-query-toptal-example/blob/master/src/shared/redux/store.ts#L24
//  https://github.com/reduxjs/redux-toolkit/issues/556#issuecomment-639154965

export const modelsSlice = createSlice({
    name: 'models',
    initialState,
    reducers: {
        addSelectedModels: (
            state,
            action: PayloadAction<{
                ids: number[];
                isAppend?: boolean;
            }>,
        ) => {
            state.selectedModels = action.payload.isAppend
                ? uniq([...state.selectedModels, ...action.payload.ids])
                : action.payload.ids;
        },
        removeSelectedModel: (state, action: PayloadAction<number>) => {
            state.selectedModels = state.selectedModels.filter(id => id !== action.payload);
        },
        replaceSelectedModel: (
            state,
            action: PayloadAction<{
                prevId: number;
                nextId: number;
            }>,
        ) => {
            const ids = state.selectedModels.slice();
            ids.splice(ids.indexOf(action.payload.prevId), 1, action.payload.nextId);
            state.selectedModels = ids;
        },

        addObjectModel: (state, action: PayloadAction<ObjectModelModel>) => {
            state.data = {
                ...state.data,
                ...{ [action.payload.id]: action.payload },
            };
        },
        updateObjectModelRelatedData: (
            state,
            action: PayloadAction<{
                model_id: number;
                data: RelatedObjectModelData;
            }>,
        ) => {
            state.relatedModelsData = {
                ...state.relatedModelsData,
                [action.payload.model_id]: {
                    ...state.relatedModelsData[action.payload.model_id],
                    ...action.payload.data,

                    ...(action.payload.data.analysing_errors
                        ? {
                              analysing_errors: {
                                  ...state.relatedModelsData[action.payload.model_id]?.analysing_errors,
                                  ...action.payload.data.analysing_errors,
                              },
                          }
                        : {}),

                    ...(action.payload.data.wall_thickness
                        ? {
                              wall_thickness: {
                                  ...state.relatedModelsData[action.payload.model_id]?.wall_thickness,
                                  ...action.payload.data.wall_thickness,
                              },
                          }
                        : {}),
                },
            };
        },

        startModelsPolling: state => {
            state.isPollingActive = true;
        },
        stopModelsPolling: state => {
            state.isPollingActive = false;
        },

        setModelsVerified: (state, action: PayloadAction<boolean>) => {
            state.modelsVerified = action.payload;
        },

        load: (state, action: PayloadAction<number[]>) => {
            state.isLoading = true;
        },
        loadSuccess: (state, action: PayloadAction<Record<string, ObjectModelModel>>) => {
            state.isLoading = false;
            state.data = {
                ...state.data,
                ...action.payload,
            };
        },
        loadFailure: state => {
            state.isLoading = false;
        },

        update: (
            state,
            action: PayloadAction<{
                modelId: number;
                data: UpdateModelPayload | UpdateNonCadModelPayload;
            }>,
        ) => {
            state.isUpdating = true;
        },
        updateSuccess: (state, action: PayloadAction<ObjectModelModel>) => {
            state.isUpdating = false;
            state.data[action.payload.id] = action.payload;
        },
        updateFailure: state => {
            state.isUpdating = false;
        },

        scale: (state, action: PayloadAction<ScaleModelPayload>) => {},
        scaleSuccess: createTransformModelReducer(),
        scaleFailure: state => {},

        rotate: (state, action: PayloadAction<RotateModelPayload>) => {},
        rotateSuccess: createTransformModelReducer(),
        rotateFailure: state => {},

        updateTransformedModelData: (state, action: PayloadAction<Record<number, ObjectModelStatus>>) => {
            state.transformedModelsData = Object.entries(state.transformedModelsData).reduce(
                (acc, [transformedModelKey, transformedModelData]) => {
                    const id = transformedModelData.data.id;
                    if (id && action.payload[id]) {
                        return {
                            ...acc,
                            [transformedModelKey]: {
                                ...transformedModelData,
                                data: {
                                    ...transformedModelData.data,
                                    status: action.payload[id],
                                },
                            },
                        };
                    }

                    return { ...acc, [transformedModelKey]: transformedModelData };
                },
                {},
            );
        },
        deleteTransformedModel: (state, action: PayloadAction<TransformModelPayload>) => {
            const key = composeTransformedModelPollingId(action.payload);

            return {
                ...state,
                transformedModelsData: Object.entries(state.transformedModelsData).reduce(
                    (acc, [transformedModelKey, transformedModelData]) => {
                        if (key === transformedModelKey) {
                            return {
                                ...acc,
                                [key]: {
                                    ...transformedModelData,
                                    deleted: true,
                                },
                            };
                        }

                        return { ...acc, [transformedModelKey]: transformedModelData };
                    },
                    {},
                ),
            };
        },
        startTransformedModelPolling: state => {
            state.isTransformedModelPollingActive = true;
        },
        stopTransformedModelPolling: state => {
            state.isTransformedModelPollingActive = false;
        },
    },
    extraReducers: builder => {
        builder.addCase(appActions.invalidateStore, (state, { payload }) =>
            payload.purge >= InvalidateLevel.Models ? { ...initialState } : state,
        );
    },
});

export const modelsReducer = createPersistReducer(PersistReducerConfigs.models, modelsSlice.reducer);
export const modelsActions = modelsSlice.actions;

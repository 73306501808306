import get from 'lodash/get';
import { RootState } from '@types';
import { createSelector } from 'reselect';
import { selectWidgetMaterialId, selectWidgetModelId, selectWidgetModel } from '../quotation';

export const selectThinFaces = (state: RootState) => state.wallThickness.thinFaces;
export const selectWallThicknessData = (state: RootState) => state.wallThickness.data;
export const selectPollingWallThicknessIsActive = (state: RootState) => state.wallThickness.isPollingActive;

export const selectWallThicknessResult = createSelector(
    selectWidgetModelId,
    selectWidgetMaterialId,
    selectWallThicknessData,
    (parentModelId, materialId, wallThicknessData) => {
        if (!parentModelId || !materialId) {
            return undefined;
        }

        return get(wallThicknessData, [parentModelId, materialId], undefined);
    },
);

// export const selectSkipWallThicknessPolling = (state: RootState) => {
//     const isActive = selectPollingWallThicknessIsActive(state);
//     const currentModel = selectWidgetModel(state);
//     const materialId = selectWidgetMaterialId(state);
//     const materialBelongsCncCutter = selectMaterialBelongsCncCutter(state, materialId);
//     const wallThicknessResult = selectWallThicknessResult(state);
//     return [
//         isActive,
//         !materialId,
//         !currentModel?.shouldCheckWallThickness,
//         wallThicknessResult && wallThicknessResult.status !== 'not_processed', // already loaded
//     ].some(Boolean);
// };

export const selectShouldWallThicknessPoll = (state: RootState) => {
    const currentModel = selectWidgetModel(state);
    const materialId = selectWidgetMaterialId(state);
    const wallThicknessResult = selectWallThicknessResult(state);
    return [
        materialId,
        currentModel && currentModel.shouldCheckWallThickness,
        !wallThicknessResult || wallThicknessResult.status === 'not_processed',
    ].every(Boolean);
};

import cn from 'classnames';
import { useAppSelector } from '@app/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { selectPricingWarnings } from '@modules/pricing';
import { Typography } from '@react-md/typography';
import { Tooltipped } from '@react-md/tooltip';
import { Icons } from '@components/icons';
import { Alert, AlertHeading } from '@components/alert';
import { getNodeIdComposer } from '@utils';
import {
    MinBendsRadiusesNotPassedProps,
    MinBendsRadiusesPassedProps,
    ModelNotificationMinBendsRadiusesProps,
} from '../types';
import { AskForHelp } from '../../../ask-for-help';
import { ModelViewerPrefix } from '../../constants';
import { PricingWarningCode } from '@types';

import styles from '../model-notifications.module.scss';

const _id = getNodeIdComposer(ModelViewerPrefix, 'min_bends_radiuses');

export const ModelNotificationMinBendsRadiuses = ({ model }: ModelNotificationMinBendsRadiusesProps) => {
    const { t } = useTranslation();
    const priceWarnings = useAppSelector(selectPricingWarnings);
    const hasPriceWarning = Boolean(priceWarnings?.find(warning => warning.code === PricingWarningCode.SanityWarning));

    if (!priceWarnings) {
        return null;
    }

    if (hasPriceWarning) {
        return <MinBendsRadiusesNotPassed t={t} model={model} />;
    }

    return <MinBendsRadiusesPassed t={t} />;
};

const MinBendsRadiusesPassed = ({ t }: MinBendsRadiusesPassedProps) => {
    return (
        <Alert show className={styles.alert} variant="outlined" type="success">
            <AlertHeading customIcon={<Icons.CheckCircleTwo className="rmd-typography--theme-success" />}>
                <Typography type="body-2" component="span" className={styles.message}>
                    {t('messages.minBendsRadiusesPassed', 'Minimum radii test passed')}
                </Typography>
            </AlertHeading>
        </Alert>
    );
};

const MinBendsRadiusesNotPassed = ({ model, t }: MinBendsRadiusesNotPassedProps) => {
    return (
        <Alert show className={styles.alert} variant="outlined" type="warning">
            <AlertHeading customIcon={<Icons.Warning className="rmd-typography--theme-warning" />}>
                <div className={styles['warning-box']}>
                    <div className={styles.message}>
                        <Typography type="body-2" component="span" className={styles.messageText}>
                            {t(
                                'messages.minBendsRadiusesNotPassed',
                                'One or more bends have a minimum radius smaller than allowed for the selected sheet',
                            )}
                        </Typography>
                        <Tooltipped
                            id={_id('hint')}
                            tooltip={
                                <Trans
                                    i18nKey="messages.minBendsRadiusesAddNotif"
                                    defaults={`Final bends' radiuses could turn out bigger than it is in your model.
                                        You can load other model or ask for help`}
                                />
                            }
                        >
                            <Icons.HelpCircle className={cn(styles.messageHelpIcon, 'rmd-typography--theme-primary')} />
                        </Tooltipped>
                    </div>
                    <AskForHelp modelId={model.id} modelLabel={model.title} />
                </div>
            </AlertHeading>
        </Alert>
    );
};

import { axiosInstance } from '@services/axios-instance';
import {
    PagedResponse,
    CreateClientForm,
    ClientRepresentationSerializer,
    ClientRepresentationDetailedSerializer,
    LoadClientsParams,
} from '@types';
import { filterEmptyValues } from '@utils';
import { PaginationConfig } from '@constants';

export class ClientService {
    static init() {
        return new ClientService();
    }

    loadClients(params?: LoadClientsParams) {
        return axiosInstance.get<PagedResponse<ClientRepresentationSerializer>>('/v2/clients/', {
            params: { format: 'json', limit: PaginationConfig.pageSize, ...params },
        });
    }

    loadClient(userId: number) {
        return axiosInstance.get<ClientRepresentationDetailedSerializer>(`/v2/clients/${userId}/`, {
            params: {
                format: 'json',
            },
        });
    }

    getClientInfo() {
        return axiosInstance.get<ClientRepresentationDetailedSerializer>(`/v2/clients/me/`, {
            params: {
                format: 'json',
            },
        });
    }

    createClient(form: CreateClientForm) {
        return axiosInstance.post<ClientRepresentationSerializer>('/v2/clients/', filterEmptyValues(form));
    }
}

import * as Sentry from '@sentry/react';
import i18next from 'i18next';
import { AxiosError } from 'axios';
import { concat, EMPTY, from } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { StateObservable } from 'redux-observable';
import { Action } from '@reduxjs/toolkit';
import { captureException } from '@sentry/browser';
import { AppSettingsData, RootState } from '@types';
import { exposedApi } from '@expose';
import { AppService } from '@services';
import { i18nSetup, oni18nextInitialized, validateCustomVariables } from '@utils';
import { clientActions, selectClientId } from '../client';
import { appActions } from './slice';

function setupSentryScope({ user_id }: AppSettingsData) {
    oni18nextInitialized(() => Sentry.setUser({ language: i18next.language }));

    i18next.on('languageChanged', (language: string) => {
        Sentry.setUser({ id: user_id, language });
    });
}

function setCustomLocale(customLocale: string) {
    const changeLanguage = () => {
        // if the language changed after setting the custom locale, here it will overwrite the previous selection,
        // so if the company has a custom locale, we hide the dropdown with the language
        if (i18next.language !== customLocale) {
            i18next.changeLanguage(customLocale);
        }
    };

    oni18nextInitialized(changeLanguage);
}

function setup(data: AppSettingsData) {
    const content = data.widget_content_customization;
    if (content && Object.keys(content).length) {
        content.variables && content.variables && validateCustomVariables(content.variables);
    }

    // call it here as the company is guaranteed to exist
    exposedApi.listen();
    i18nSetup();

    setupSentryScope(data);

    if (data.custom_locale) {
        setCustomLocale(data.custom_locale);
    }
}

export const getInitAppSucceedActions = (data: AppSettingsData, state: RootState) => {
    const clientId = selectClientId(state);
    const actions: Action[] = [];

    if (clientId) {
        actions.push(clientActions.load(clientId));
    }

    actions.push(appActions.initAppSuccess(data));

    return actions;
};

type GetExtraActions = (data: AppSettingsData, state: RootState) => Action[];

export const getAppSettingsObservable$ = (
    state$: StateObservable<RootState>,
    getBeforeInitActions?: GetExtraActions,
    getAfterInitActions?: GetExtraActions,
) => {
    return from(AppService.init().getSettings()).pipe(
        withLatestFrom(state$),
        switchMap(([{ data }, state]) => {
            // we have to call setup before emitting actions (means before rendering the page)
            setup(data);

            const beforeInitActions = getBeforeInitActions ? getBeforeInitActions(data, state) : [];
            const afterInitActions = getAfterInitActions ? getAfterInitActions(data, state) : [];
            const _actions = beforeInitActions.concat(getInitAppSucceedActions(data, state));
            const actions = _actions.concat(afterInitActions);
            return concat(actions);
        }),
        catchError((error: AxiosError) => {
            // invalid token case, interceptors of axios is invoked
            if (!error.isAxiosError) {
                const eventId = captureException(error);
            }

            return EMPTY;
        }),
    );
};

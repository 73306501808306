import { AxiosError } from 'axios';
import { generatePath } from 'react-router';
import { concat, EMPTY, from, of } from 'rxjs';
import { filter, finalize, switchMap, catchError, withLatestFrom, take, map } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { Action } from '@reduxjs/toolkit';
import {
    AppEpic,
    InvalidateLevel,
    InvalidateStoreConfig,
    OrderFormErrors,
    OrderStatuses,
    OrderPopup,
    ObjectModel,
    Product,
    InitialOrderCreateRequest,
} from '@types';
import { AppService, OrderService } from '@services';
import {
    AppSession,
    formatResponseErrors,
    getSchemaObject,
    replaceUrl,
    BuildOrderUrlProps,
    buildOrderUrl,
    findProductByModel,
    getProductsDrawings,
    reverse,
} from '@utils';
import { AnalyticsCustomEvents, ROUTES } from '@constants';
import { http404, router } from '@components/routes';
import { appActions, selectAppSettings, selectAppCompanyName } from '../app';
import { popupsActions } from '../ui';
import { quotationActions } from '../quotation';
import { modelsActions, selectParentModelsList, createModelsPolling } from '../models';
import { clientActions, selectClientId } from '../client';
import { uploadModelsActions, selectUploadJob, selectIsUploadJobBinding } from '../upload-models';
import { productActions } from '../product';
import { startPreselectionPolling } from '../preselection';
import { selectIqtModeOn } from '../user';
import { filterOrderCreationData } from './helpers';
import { orderActions } from './slice';
import {
    selectOrderData,
    selectOrderId,
    selectOrderCreatingPayload,
    selectOrderQuestionnaireSchema,
    selectAdditionalContactsList,
} from './selectors';

const setOrderUrl = (args: BuildOrderUrlProps) => {
    replaceUrl(buildOrderUrl(args));
};

/*
    Epic mapping "Condition" to "Request":

    1) no current + no param -> do nothing (previously here was "create" operation)
    2) no current + invalid param -> 404
    3) current + invalid param -> load current
    4) current + no param -> load current
    5) current == param -> load current
    6) current != param -> load param
    7) no current + param -> load param
 */
const defineOrderEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(orderActions.defineOrder.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const { orderId: _orderId, company, setupUrl } = action.payload;
            const orderId = _orderId ? parseInt(_orderId) : undefined;
            const currentOrderId = selectOrderId(state);
            const order = selectOrderData(state);

            // invalid URL
            if (Number.isNaN(orderId)) {
                return currentOrderId
                    ? of(
                          orderActions.load({
                              orderId: undefined,
                              currentOrderId,
                              setupUrl,
                          }),
                      )
                    : EMPTY.pipe(finalize(() => http404(company)));
            }

            if (currentOrderId || orderId) {
                // load previously created order
                if ((currentOrderId && !orderId) || (currentOrderId && orderId && currentOrderId === orderId)) {
                    // moving between pages
                    if (order) {
                        return EMPTY;
                    }

                    return of(
                        orderActions.load({
                            orderId,
                            currentOrderId,
                            setupUrl,
                        }),
                    );
                }

                // load abandoned order
                if (orderId) {
                    // return EMPTY.pipe(finalize(() => http404(company)));
                    return of(
                        orderActions.load({
                            orderId,
                            currentOrderId,
                            setupUrl,
                            replace: true,
                        }),
                    );
                }
            }

            // order doesn't exist
            return EMPTY;
            // return of(orderActions.create.request());
        }),
    );

const loadOrderEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(orderActions.load.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const company = selectAppCompanyName(state);
            const iqtModeOn = selectIqtModeOn(state);

            const { orderId, currentOrderId, replace, setupUrl } = action.payload;
            const requestOrderId = orderId || currentOrderId;

            return from(OrderService.init().loadInitialOrder(requestOrderId!)).pipe(
                switchMap(({ data }) => {
                    const orderNotDraft =
                        data.status !== OrderStatuses.Initial && data.status !== OrderStatuses.Created;
                    // the order has already been sent, just redirect to view (shortIqtModeOn does not have such page)
                    if (orderNotDraft && !AppSession.shortIqtModeOn) {
                        return EMPTY.pipe(
                            finalize(() =>
                                router.navigate(
                                    reverse('userOrder', {
                                        orderId: data.id,
                                    }),
                                    { replace: true },
                                ),
                            ),
                        );
                    }

                    const actions: Array<Action> = [];
                    if (replace) {
                        actions.push(appActions.invalidateStore({ purge: InvalidateLevel.Models }));

                        if (iqtModeOn) {
                            actions.push(
                                ...[clientActions.setClientId(data.buyer_id), clientActions.load(data.buyer_id)],
                            );
                        }

                        if (data.upload_job) {
                            actions.push(uploadModelsActions.setUploadJob(data.upload_job));
                        }

                        const ids = data.products.map(({ parent_model_id }) => parent_model_id);

                        actions.push(
                            ...[
                                modelsActions.addSelectedModels({
                                    ids,
                                }),
                                createModelsPolling() as unknown as Action,
                                startPreselectionPolling() as unknown as Action,
                            ],
                        );
                    }

                    const drawings = getProductsDrawings(data.products);
                    actions.push(productActions.setDrawings(drawings));

                    return concat([...actions, orderActions.loadSuccess(data)]).pipe(
                        finalize(() => {
                            setupUrl && setOrderUrl({ orderId: data.id, page: setupUrl });
                        }),
                    );
                }),

                catchError(error => {
                    const errorCode = error.response?.status;

                    // an attempt to load a prohibited cart
                    if (errorCode === 404 && replace && currentOrderId) {
                        return concat([
                            orderActions.loadFailure(),
                            orderActions.load({
                                currentOrderId,
                                setupUrl,
                                orderId: undefined,
                                replace: false,
                            }),
                        ]);
                    }

                    // 500 server error or any unexpected error, if we have currentCartId we should invalidate the store
                    if (currentOrderId) {
                        return concat([
                            orderActions.loadFailure(),
                            appActions.invalidateStore({
                                purge: InvalidateLevel.Order,
                                redirect: reverse('widgetUpload'),
                                redirectByRouter: true,
                            }),
                        ]);
                    }

                    return of(orderActions.loadFailure()).pipe(finalize(() => http404(company)));
                }),
            );
        }),
    );

const createOrderObservable$ = ({ clientId, data }: { clientId?: number; data: InitialOrderCreateRequest }) => {
    const service = OrderService.init();
    return from(clientId ? service.createInitialOrderIqt(clientId, data) : service.createInitialOrder(data)).pipe(
        switchMap(({ data }) =>
            of(orderActions.createSuccess(data)).pipe(
                finalize(() => setOrderUrl({ orderId: data.id, page: 'widgetOrder' })),
            ),
        ),
        catchError(() => of(orderActions.createFailure())),
    );
};

const createOrderEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(orderActions.create.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const upload_job = selectUploadJob(state);
            const payload_passthrough = selectOrderCreatingPayload(state);
            const isUploadJobBinding = selectIsUploadJobBinding(state);
            const clientId = selectClientId(state);

            const payload = { data: { upload_job, payload: payload_passthrough }, clientId };

            if (isUploadJobBinding) {
                return action$.pipe(
                    filter(uploadModelsActions.rebindUploadJobSuccess.match),
                    switchMap(action => createOrderObservable$(payload)),
                    take(1),
                );
            }

            return createOrderObservable$(payload);
        }),
    );

// todo rtk query
const updateOrderEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(orderActions.update.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const { orderId, data } = action.payload;

            return from(OrderService.init().updateInitialOrder(orderId, data)).pipe(
                map(({ data }) => orderActions.updateSuccess(data)),
                catchError(() => of(orderActions.updateFailure())),
            );
        }),
    );

// todo rtk query
const submitOrderEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(orderActions.submit.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const { form, onSuccess, onError } = action.payload;
            const orderId = selectOrderId(state);
            const settings = selectAppSettings(state)!;
            const additionalContactsList = selectAdditionalContactsList(state);
            const schema = selectOrderQuestionnaireSchema(state);
            const fields = getSchemaObject(schema);
            const company = selectAppCompanyName(state);
            const { use_new_payment_page } = settings;

            return from(
                OrderService.init().submitInitialOrder(
                    orderId!,
                    filterOrderCreationData(form, settings, fields, additionalContactsList),
                ),
            ).pipe(
                switchMap(({ data }) => {
                    const { status } = form;
                    //***** Real company_order_id is inside data, not inside initialOrder (data from selectOrderData)! *****//
                    const {
                        invoice_hash: hash,
                        invoice_id: invoiceId,
                        pay_url: payUrl,
                        order_url,
                        company_order_id,
                    } = data;
                    const { currency, models_price, delivery_price, total_price } = selectOrderData(state) || {};

                    if (status === OrderStatuses.Placed || status === OrderStatuses.WaitingForReview) {
                        window.Analytics?.track(AnalyticsCustomEvents.OrderStatusChange, {
                            company_order_id,
                            currency,
                            models_price,
                            delivery_price,
                            total_price,
                            status,
                        });
                    }

                    const redirectOptions: Pick<InvalidateStoreConfig, 'redirect' | 'redirectByRouter'> = {};

                    if (status === OrderStatuses.Created) {
                        Object.assign(redirectOptions, {
                            redirect: order_url,
                        });
                    } else {
                        const invoicePath = generatePath(ROUTES.invoice, {
                            company,
                            invoiceId,
                            hash,
                        });
                        Object.assign(redirectOptions, {
                            redirect: use_new_payment_page ? invoicePath : payUrl,
                            redirectByRouter: use_new_payment_page,
                        });
                    }

                    const actions = [
                        orderActions.submitSuccess(data),
                        appActions.invalidateStore({
                            purge: InvalidateLevel.Order,
                            ...redirectOptions,
                        }),
                    ] as Array<Action>;

                    if (status === OrderStatuses.FirmOfferSent) {
                        actions.push(
                            popupsActions.open({
                                type: OrderPopup.Notification,
                            }),
                        );
                    }

                    return concat(actions).pipe(finalize(() => onSuccess && onSuccess(data)));
                }),
                catchError((error: AxiosError<OrderFormErrors>) => {
                    const errors = formatResponseErrors<OrderFormErrors>(
                        error?.response?.data || ({} as OrderFormErrors),
                    );

                    return of(orderActions.submitFailure(errors)).pipe(finalize(() => onError && onError(errors)));
                }),
            );
        }),
    );

// todo rtk query
const applyPromocodeEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(orderActions.applyPromocode.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const { orderId, data } = action.payload;

            return from(OrderService.init().applyPromocode(orderId, data)).pipe(
                map(({ data }) => orderActions.applyPromocodeSuccess(data)),
                catchError((error: AxiosError) => {
                    const errorCode = error.response?.status;
                    return of(orderActions.applyPromocodeFailure(errorCode));
                }),
            );
        }),
    );

// todo rtk query
const loadOrderQuestionnaire: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(orderActions.loadQuestionnaire.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            return from(AppService.init().getOrderQuestionnaire()).pipe(
                switchMap(({ data: { config } }) => {
                    return of(orderActions.loadQuestionnaireSuccess(config));
                }),
                catchError(() => of(orderActions.loadQuestionnaireFailure())),
            );
        }),
    );

export const orderEpics = combineEpics(
    defineOrderEpic,
    loadOrderEpic,
    createOrderEpic,
    updateOrderEpic,
    submitOrderEpic,
    applyPromocodeEpic,
    loadOrderQuestionnaire,
);

import pick from 'lodash/pick';
import {
    ObjectModelCncFeature,
    CncFeatureTypes,
    CncFeatureTypesOption,
    ObjectModel,
    TechnologyMaterial,
    SpecificationInitialValues,
    PriceConfigForm,
    TechnologyMaterialOptions,
    PriceConfigFormFields,
} from '@types';
import { PriceConfigFields } from '@constants';
import { SpecificationFormParams, CncFeaturesListItem } from './types';

// Features = Holes + Reductions
// threads === suitable CNC feature options from material

type FilterFeatureOptionParams = { hole: ObjectModelCncFeature; option: CncFeatureTypesOption };

function isFeatureOptionAvailable({ hole, option }: FilterFeatureOptionParams) {
    return (
        hole.ratio >= option.min_ratio &&
        (hole.shape_type === option.shape_type || option.shape_type === '') &&
        (hole.through === option.through || option.through === '')
    );
}

function isHoleTooDeep({ hole, option }: FilterFeatureOptionParams) {
    return hole.ratio >= option.max_ratio;
}

function filterModelCncFeatures(model: ObjectModel, options: CncFeatureTypes): CncFeaturesListItem[] {
    return model
        .cnc_features!.filter(hole => {
            return options[hole.type]?.length;
        })
        .map(hole => {
            const suitableOptions = options[hole.type]
                .filter(option => isFeatureOptionAvailable({ hole, option }))
                .map(option => ({ ...option, isTooDeep: isHoleTooDeep({ hole, option }) }));

            return {
                type: hole.type,
                type_display: hole.type_display,
                diameter: hole.diameter,
                quantity: hole.quantity,
                threads: suitableOptions,
                shape_type: hole.shape_type,
                through: hole.through,
                ratio: hole.ratio,
            };
        })
        .filter(hole => hole.threads.length);
}

export function getSpecificationOptions(model: ObjectModel, material?: TechnologyMaterial): SpecificationFormParams {
    const fields = pick(material, PriceConfigFields);

    return Object.entries(fields)
        .filter(([_, value]) => {
            if (value) {
                return Array.isArray(value) ? value.length : Object.keys(value).length;
            }
            return false;
        })
        .reduce((acc, [key, value]) => {
            switch (key) {
                case 'cnc_features_types': {
                    const options = value as CncFeatureTypes;
                    const features = filterModelCncFeatures(model, options);
                    return features.length ? { ...acc, [key]: features } : acc;
                }

                case 'extra_fieldsets': {
                    const options = value as SpecificationFormParams['extra_fieldsets'];
                    const groups = options.filter(list => list.options.length);
                    return groups.length ? { ...acc, [key]: groups } : acc;
                }

                default:
                    return { ...acc, [key]: value };
            }
        }, {} as SpecificationFormParams);
}

export function getSpecificationInitialValues({
    formParams,
    initialThickness,
}: SpecificationInitialValues): PriceConfigForm {
    return Object.entries(formParams).reduce((acc, [key, value]) => {
        switch (key) {
            case 'cnc_features_types': {
                const options = value as SpecificationFormParams['cnc_features_types'];
                const defaultValue = options.map(item => ({
                    type: item.type,
                    diameter: item.diameter,
                    quantity: item.quantity,
                    thread: item.threads[0].id,
                }));
                return { ...acc, [PriceConfigFormFields.Features]: defaultValue };
            }
            case 'post_production': {
                return { ...acc, [key]: [] };
            }
            case 'extra_fieldsets': {
                const options = value as SpecificationFormParams['extra_fieldsets'];
                const defaultValue = options.map(val => {
                    return {
                        fieldset: val.id,
                        choice: val.options[0].id,
                    };
                });
                return { ...acc, [key]: defaultValue };
            }
            case 'thickness': {
                const initial =
                    initialThickness &&
                    (value as TechnologyMaterialOptions).find(thickness => thickness.uuid === initialThickness);

                return {
                    // This fallback is for case if first material becomes not suitable after analysis
                    // or cases without preselection and returning config: null like CNC Cutter
                    ...acc,
                    [key]: (initial || value[0]).uuid,
                };
            }
            default:
                return { ...acc, [key]: (value as TechnologyMaterialOptions)[0].uuid };
        }
    }, {} as PriceConfigForm);
}

import {
    Vector3,
    Euler,
    Color,
    BufferGeometry,
    EdgesGeometry,
    BoxGeometry,
    Material,
    LineBasicMaterial,
    MeshPhongMaterial,
    Object3D,
    Scene,
    AmbientLight,
    DirectionalLight,
    PointLight,
    Mesh,
    LineSegments,
    BoxHelper,
    Box3Helper,
    DirectionalLightHelper,
} from 'three';
import { GUI } from 'lil-gui';
import { ModelViewer, ModelViewerObjects, ModelViewerVariables } from '@utils';
import { Grid, ModelEdges } from '@utils/viewer/model-viewer/objects';

export const Transforms = ['position', 'rotation', 'scale'] as const;

interface VectorGuiConfig {
    enable: boolean;
    inner: boolean;
    step: number;
}
type TransformType = (typeof Transforms)[number];
type VectorGui = Record<TransformType, VectorGuiConfig>;

export const addVector = function (gui: GUI, name: string, vector: Vector3 | Euler, step = 0.1, inner = false) {
    const folder = inner ? gui : gui.addFolder(name);

    if (Math.abs(vector.x) >= 0) folder.add(vector, 'x').step(step);
    if (Math.abs(vector.y) >= 0) folder.add(vector, 'y').step(step);
    if (Math.abs(vector.z) >= 0) folder.add(vector, 'z').step(step);
    // if (Math.abs(vector.w) >= 0) folder.add(vector, 'w').step(step);

    return folder;
};

export const addObject3D = function (
    gui: GUI,
    name: string,
    object: Object3D,
    { recursive = false, inner = false, stepPosition = 1, stepRotation = 0.02, stepScale = 0.01 } = {},
) {
    const folder = inner ? gui : gui.addFolder(name);
    addVector(folder, 'position', object.position, stepPosition);
    addVector(folder, 'rotation', object.rotation, stepRotation);
    addVector(folder, 'scale', object.scale, stepScale);

    // manageRecursive(recursive, object, folder);

    return folder;
};

function handleColorChange(color: Color) {
    return function (value: any) {
        if (typeof value === 'string') {
            value = value.replace('#', '0x');
        }

        color.setHex(value);
    };
}

function needsUpdate(material: Material, geometry: BufferGeometry) {
    return function () {
        // material.side = parseInt(material.side); //Ensure number
        material.needsUpdate = true;
        geometry.attributes.position.needsUpdate = true;
        if (geometry.attributes.normal) {
            geometry.attributes.normal.needsUpdate = true;
        }
        if (geometry.attributes.color) {
            geometry.attributes.color.needsUpdate = true;
        }
    };
}

function guiGeometry(gui: GUI, mesh: Mesh) {
    const geometryFolder = gui.addFolder('Geometry');
    const rotationFolder = geometryFolder.addFolder('Rotation');
    rotationFolder.add(mesh.rotation, 'x', 0, Math.PI).name('Rotate X Axis');
    rotationFolder.add(mesh.rotation, 'y', 0, Math.PI).name('Rotate Y Axis');
    rotationFolder.add(mesh.rotation, 'z', 0, Math.PI).name('Rotate Z Axis');
    rotationFolder.open();
    const scaleFolder = geometryFolder.addFolder('Scale');
    scaleFolder.add(mesh.scale, 'x', 0, 2).name('Scale X Axis');
    scaleFolder.add(mesh.scale, 'y', 0, 2).name('Scale Y Axis');
    scaleFolder.add(mesh.scale, 'z', 0, 2).name('Scale Z Axis');
    scaleFolder.open();
}

function guiMaterial(gui: GUI, material: Material, geometry: BufferGeometry) {
    const folder = gui.addFolder('Material');

    folder.add(material, 'transparent').onChange(needsUpdate(material, geometry));
    folder.add(material, 'opacity', 0, 1).step(0.01);
    folder.add(material, 'depthTest');
    folder.add(material, 'depthWrite');
    folder.add(material, 'alphaTest', 0, 1).step(0.01).onChange(needsUpdate(material, geometry));
    folder.add(material, 'visible');
    // folder.add(material, 'side', constants.side).onChange(needsUpdate(material, geometry));
    folder.open();
}

function guiMeshPhongMaterial(gui: GUI, material: MeshPhongMaterial, geometry: BufferGeometry) {
    const data = {
        color: material.color.getHex(),
        emissive: material.emissive.getHex(),
        specular: material.specular.getHex(),
        // envMaps: envMapKeys[0],
        // map: diffuseMapKeys[0],
        // alphaMap: alphaMapKeys[0],
    };

    const folder = gui.addFolder('MeshPhongMaterial');

    folder.addColor(data, 'color').onChange(handleColorChange(material.color));
    folder.addColor(data, 'emissive').onChange(handleColorChange(material.emissive));
    folder.addColor(data, 'specular').onChange(handleColorChange(material.specular));

    folder.add(material, 'shininess', 0, 100);
    folder.add(material, 'flatShading').onChange(needsUpdate(material, geometry));
    folder.add(material, 'wireframe');
    folder.add(material, 'vertexColors').onChange(needsUpdate(material, geometry));
    // folder.add(material, 'fog');
    // folder.add(data, 'envMaps', envMapKeys).onChange(updateTexture(material, 'envMap', envMaps));
    // folder.add(data, 'map', diffuseMapKeys).onChange(updateTexture(material, 'map', diffuseMaps));
    // folder.add(data, 'alphaMap', alphaMapKeys).onChange(updateTexture(material, 'alphaMap', alphaMaps));
    // folder.add(material, 'combine', constants.combine).onChange(updateCombine(material));
    // folder.add(material, 'reflectivity', 0, 1);
    // folder.add(material, 'refractionRatio', 0, 1);
    folder.open();
}

function guiLineBasicMaterial(gui: GUI, material: LineBasicMaterial, geometry: BufferGeometry) {
    const data = {
        color: material.color.getHex(),
    };

    const folder = gui.addFolder('LineBasicMaterial');

    folder.addColor(data, 'color').onChange(handleColorChange(material.color));
    folder.add(material, 'linewidth', 0, 10);
    folder.add(material, 'linecap', ['butt', 'round', 'square']);
    folder.add(material, 'linejoin', ['round', 'bevel', 'miter']);
    folder.add(material, 'vertexColors').onChange(needsUpdate(material, geometry));
    // folder.add(material, 'fog');
    folder.open();
}

function guiScene(gui: GUI, viewer: ModelViewer) {
    const scene = viewer.scene;
    const folder = gui.addFolder('Scene');
    const background = scene.background as Color;
    const sceneSettings = {
        background: background.getHex(),
    };
    folder.addColor(sceneSettings, 'background').onChange(handleColorChange(background));

    // lights
    const ambientLight = scene.getObjectByName('ambientLight') as AmbientLight;
    const alFolder = folder.addFolder('ambient light');
    const alSettings = { color: ambientLight.color.getHex() };
    alFolder.add(ambientLight, 'visible');
    // .onChange(requestRenderIfNotRequested);
    alFolder.add(ambientLight, 'intensity', 0, 1, 0.1);
    alFolder.addColor(alSettings, 'color').onChange(handleColorChange(ambientLight.color));
    // alFolder.add(ambientLight, 'castShadow');
    // addObject3D(alFolder, 'ambient light', ambientLight, { inner: true });
    // addVector(alFolder, 'position', ambientLight.position, 1, true);

    const directionalLightFront = scene.getObjectByName('directionalLightFront') as DirectionalLight;
    const dl1Helper = new DirectionalLightHelper(directionalLightFront, 3);
    scene.add(dl1Helper);
    const dl1Folder = folder.addFolder('directional light front');
    const dl1Settings = { visible: true, color: directionalLightFront.color.getHex() };
    dl1Folder.add(dl1Settings, 'visible').onChange((value: boolean) => {
        directionalLightFront.visible = value;
        dl1Helper.visible = value;
    });
    dl1Folder.add(directionalLightFront, 'intensity', 0, 1, 0.1);
    dl1Folder.addColor(dl1Settings, 'color').onChange(handleColorChange(directionalLightFront.color));
    dl1Folder.add(directionalLightFront, 'castShadow');
    addVector(dl1Folder, 'position', directionalLightFront.position, 1, true);

    const directionalLightBack = scene.getObjectByName('directionalLightBack') as DirectionalLight;
    const dl2Helper = new DirectionalLightHelper(directionalLightBack, 3);
    scene.add(dl2Helper);
    const dl2Folder = folder.addFolder('directional light back');
    const dl2Settings = { visible: true, color: directionalLightBack.color.getHex() };
    dl2Folder.add(dl2Settings, 'visible').onChange((value: boolean) => {
        directionalLightBack.visible = value;
        dl2Helper.visible = value;
    });
    dl2Folder.add(directionalLightBack, 'intensity', 0, 1, 0.1);
    dl2Folder.addColor(dl2Settings, 'color').onChange(handleColorChange(directionalLightBack.color));
    dl2Folder.add(directionalLightBack, 'castShadow');
    addVector(dl2Folder, 'position', directionalLightBack.position, 1, true);

    // const light1 = new PointLight(0xffffff, 1, 0);
    // light1.position.set(0, 200, 0);
    // scene.add(light1);
    //
    // const light2 = new PointLight(0xffffff, 1, 0);
    // light2.position.set(100, 200, 100);
    // scene.add(light2);
    //
    // const light3 = new PointLight(0xffffff, 1, 0);
    // light3.position.set(-100, -200, -100);
    // scene.add(light3);

    viewer.animate(() => {
        dl1Helper.update();
        dl2Helper.update();
    });
}

function guiModel(gui: GUI, model: Mesh<BufferGeometry, MeshPhongMaterial>) {
    const folder = gui.addFolder('Model');

    // guiGeometry(folder, mesh);
    // addVector(folder, 'position', mesh.position, 1, true);

    guiMaterial(folder, model.material, model.geometry);
    guiMeshPhongMaterial(folder, model.material, model.geometry);
}

function guiModelEdges(gui: GUI, mesh?: LineSegments) {
    if (!mesh) return;

    const folder = gui.addFolder('ModelEdges');

    // TODO to update thresholdAngle you need to recreate geometry
    // folder
    //     // @ts-ignore
    //     .add((mesh.geometry as EdgesGeometry).parameters, 'thresholdAngle')
    //     .min(0)
    //     .max(180)
    //     .onChange(() => {
    //         console.log(mesh.geometry as EdgesGeometry);
    //     });
    guiMaterial(folder, mesh.material as Material, mesh.geometry);
    guiLineBasicMaterial(folder, mesh.material as LineBasicMaterial, mesh.geometry);
}

function guiModelBox(gui: GUI, mesh: Box3Helper) {
    const folder = gui.addFolder('ModelBox');

    guiMaterial(folder, mesh.material as Material, mesh.geometry);
    guiLineBasicMaterial(folder, mesh.material as LineBasicMaterial, mesh.geometry);
}

function guiPrinterBox(gui: GUI, mesh: Box3Helper) {
    const folder = gui.addFolder('PrinterBox');

    guiMaterial(folder, mesh.material as Material, mesh.geometry);
    guiLineBasicMaterial(folder, mesh.material as LineBasicMaterial, mesh.geometry);
}

function guiGrid(gui: GUI, grid: Grid) {
    const folder = gui.addFolder('Grid');
    let childCount = 1;

    grid.traverse(child => {
        if (child instanceof LineSegments) {
            const subFolder = folder.addFolder('part' + childCount++);
            guiMaterial(subFolder, child.material as Material, child.geometry);
            guiLineBasicMaterial(subFolder, child.material as LineBasicMaterial, child.geometry);
        }
    });
}

export function initGui(viewer: ModelViewer) {
    const gui = new GUI();
    const model = viewer.currentModel!;
    const modelEdges = viewer.objects[ModelViewerObjects.ModelEdges]?.object!.children[0] as LineSegments;
    const modelBoundingBox = viewer.objects[ModelViewerObjects.ModelBoundingBox]?.object!;
    const printerBoundingBox = viewer.objects[ModelViewerObjects.PrinterBoundingBox]?.object!;
    const grid = viewer.objects[ModelViewerObjects.Grid]?.object!;

    guiScene(gui, viewer);
    guiModel(gui, model as Mesh<BufferGeometry, MeshPhongMaterial>);
    guiModelEdges(gui, modelEdges);
    guiModelBox(gui, modelBoundingBox);
    guiPrinterBox(gui, printerBoundingBox);
    guiGrid(gui, grid);

    const actionsFolder = gui.addFolder('Actions');
    const customParams = {
        toJSON: function () {
            console.log(viewer.scene.toJSON());
        },
    };
    actionsFolder.add(customParams, 'toJSON').name('JSON output to console');

    // const boxGeometry = new BoxGeometry(24, 24, 24);
    // const boxMaterial = new MeshPhongMaterial({ color: 0xffffff });
    // const boxMesh = new Mesh(boxGeometry, boxMaterial);
    // viewer.scene.add(boxMesh);
    //
    // const rotationFolder = gui.addFolder('Rotation');
    // addVector(rotationFolder, 'position', boxMesh.position, 1, true);
    // rotationFolder.add(boxMesh.rotation, 'x', 0, Math.PI).name('Rotate X Axis');
    // rotationFolder.add(boxMesh.rotation, 'y', 0, Math.PI).name('Rotate Y Axis');
    // rotationFolder.add(boxMesh.rotation, 'z', 0, Math.PI).name('Rotate Z Axis');
}

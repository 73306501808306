import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ObjectModel } from '@types';
import { getFileExtensionByUrl } from '@utils';
import { LineItem } from '@components/line-items';
import { NonCadSizes } from './non-cad-sizes';
import { Typography } from 'react-md';
import { DetailsBox } from './details-box';

import styles from './non-cad-details.module.scss';

interface Props {
    model: ObjectModel;
}

export const NonCadDetails: React.FC<Props> = ({ model }) => {
    const fileExtension = getFileExtensionByUrl(model.title); // TODO model.extension?
    const { t } = useTranslation();
    return (
        <DetailsBox className={cn(styles.box, 'fade-in')}>
            <div className="fade-in">
                <Typography type="headline-5" className={styles.title}>
                    {model.title}
                </Typography>
                <NonCadSizes model={model} />
                <LineItem label={t('labels.format', 'Format')} value={fileExtension} />
            </div>
        </DetailsBox>
    );
};

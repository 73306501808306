import * as Three from 'three';
import type { ColorRepresentation, Group, Mesh } from 'three';
import { ModelViewerVariables } from '../constants';

export class ModelEdges extends Three.Group {
    constructor(model: Group | Mesh, customColor?: ColorRepresentation, thresholdAngle = 15) {
        super();

        model.traverse(child => {
            if (child instanceof Three.Mesh) {
                const geometry = new Three.EdgesGeometry(child.geometry, thresholdAngle);
                const material = new Three.LineBasicMaterial({
                    color: customColor || ModelViewerVariables.ModelEdges.Color,
                });
                const lines = new Three.LineSegments(geometry, material);
                lines.renderOrder = 5;
                this.add(lines);
            }
        });
    }
}

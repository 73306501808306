import { TFunction } from 'i18next';

export function useFormLabels(t: TFunction): Record<string, string> {
    return {
        email: t('labels.email', 'E-mail'),
        first_name: t('labels.firstName', 'First name'),
        last_name: t('labels.lastName', 'Last name'),
        phone: t('labels.phoneNumber', 'Phone number'),
        send_notification: t('labels.send_notification', 'Send registration e-mail'),
    };
}

import cn from 'classnames';
import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useWidgetMaterial } from '@pages/widget/order/hooks';
import { modelsActions, selectRelatedModelsDataById } from '@modules/models';
import { selectCorrectObjectModelId } from '@modules/quotation';
import { ObjectModelModel } from '@models';
import { getFileExtensionByUrl } from '@utils';
import { DxfViewer } from './dxf-viewer';
import { ModelViewer } from './model-viewer';
import { NonCadViewer } from './non-cad-viewer';

import styles from './viewer-handler.module.scss';

export interface ViewerProcessableModelHandlerProps {
    model: ObjectModelModel;
    isModelFileLoaded: boolean;
}

export const ViewerProcessableModelHandler = ({ model, isModelFileLoaded }: ViewerProcessableModelHandlerProps) => {
    const dispatch = useAppDispatch();
    const { widgetMaterial } = useWidgetMaterial();
    const correctObjectModelId = useAppSelector(selectCorrectObjectModelId);

    const setFileModelLoaded = useCallback(
        (modelFileUrl: string) => {
            if (isModelFileLoaded) {
                return;
            }

            dispatch(
                modelsActions.updateObjectModelRelatedData({
                    model_id: model.id,
                    data: { isModelFileLoaded: true },
                }),
            );
        },
        [dispatch, model.id, isModelFileLoaded],
    );

    const fileExtension = getFileExtensionByUrl(model.file_model_viewer_url!); // TODO model.extension?
    const isDxfFile = fileExtension === 'dxf';

    return isDxfFile ? (
        <DxfViewer model={model} onLoadFileCallback={setFileModelLoaded} />
    ) : (
        <ModelViewer
            model={model}
            correctObjectModelId={correctObjectModelId}
            material={widgetMaterial}
            onLoadFileCallback={setFileModelLoaded}
        />
    );
};

export interface ViewerHandlerProps {
    model?: ObjectModelModel;
}

export const ViewerHandler: React.FC<ViewerHandlerProps> = ({ model }) => {
    const isEmpty = !model;
    const isModelFileLoaded = useAppSelector(state => {
        return model?.is_processable ? Boolean(selectRelatedModelsDataById(state, model.id)?.isModelFileLoaded) : true;
    });

    return (
        <div className={cn([styles.box, { [styles.empty]: isEmpty, [styles.loading]: !isModelFileLoaded }])}>
            {isEmpty ? null : model.is_processable ? (
                <ViewerProcessableModelHandler model={model} isModelFileLoaded={isModelFileLoaded} />
            ) : (
                <NonCadViewer model={model} />
            )}
        </div>
    );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientRepresentationDetailedSerializer } from '@types';

interface State {
    data?: ClientRepresentationDetailedSerializer;
    isLoading: boolean;
}

const initialState: State = {
    isLoading: false,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        load: state => {
            state.isLoading = true;
        },
        loadSuccess: (state, action: PayloadAction<ClientRepresentationDetailedSerializer>) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        loadFailure: state => {
            state.isLoading = false;
        },
    },
});

export const userActions = userSlice.actions;

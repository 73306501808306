import type { MeshBasicMaterial, MeshPhongMaterial, ShaderMaterial, ColorRepresentation, Vector3 } from 'three';

export enum ModelViewerRenderMode {
    Solid = 'solid',
    XRay = 'xRay',
    Wireframe = 'wireframe',
}

export enum ModelViewerObjects {
    Axes = 'axes',
    Grid = 'grid',
    ModelEdges = 'modelEdges',
    ModelBoundingBox = 'modelBoundingBox',
    PrinterBoundingBox = 'printerBoundingBox',
    Model = 'model',
}

export type ModelMaterials = MeshPhongMaterial | ShaderMaterial | MeshBasicMaterial;

interface BaseObjectConfig {
    draw: boolean;
    active: boolean;
    color?: ColorRepresentation;
}

export type ModelViewerConfigType = {
    darkModeOn: boolean;
    debug: boolean;
    renderMode: ModelViewerRenderMode;
    rotateModeOn: boolean;
    objects: {
        [ModelViewerObjects.Axes]: BaseObjectConfig;
        [ModelViewerObjects.Grid]: BaseObjectConfig;
        [ModelViewerObjects.ModelEdges]: BaseObjectConfig;
        [ModelViewerObjects.ModelBoundingBox]: BaseObjectConfig;
        [ModelViewerObjects.PrinterBoundingBox]: BaseObjectConfig & {
            size: string | Vector3;
        };
        [ModelViewerObjects.Model]: BaseObjectConfig;
    };
    customBackgroundColor?: string;
};

export const ModelViewerConfig: ModelViewerConfigType = {
    darkModeOn: false,
    debug: false,
    renderMode: ModelViewerRenderMode.Solid,
    rotateModeOn: false,
    objects: {
        [ModelViewerObjects.Axes]: {
            draw: true,
            active: true,
        },
        [ModelViewerObjects.Grid]: {
            draw: true,
            active: true,
        },
        [ModelViewerObjects.ModelEdges]: {
            draw: true,
            active: true,
        },
        [ModelViewerObjects.ModelBoundingBox]: {
            draw: true,
            active: true,
        },
        [ModelViewerObjects.PrinterBoundingBox]: {
            draw: true,
            active: true,
            size: '',
        },
        [ModelViewerObjects.Model]: {
            draw: true,
            active: true,
            color: '',
        },
    },
};

// ViewerObjectsConfig
enum Scene {
    LightBackgroundColor = '#dddddd',
    DarkBackgroundColor = '#111111',
}

enum Model {
    Color = '#111111',
    ThinFaceColor = '#FF0000',
}

enum ModelEdges {
    Color = '#161616',
}

enum ModelBox {
    Color = '#555555',
}

enum PrinterBox {
    Color = '#777777',
}

enum Axes {
    xAxisColor = '#f5000f',
    yAxisColor = '#00b712',
    zAxisColor = '#16009d',
}

enum Grid {
    SectionColor = '#888888',
    CellColor = '#999999',
    DarkColor = '#030303',
}

export const ModelViewerVariables = {
    Scene,
    Model,
    ModelEdges,
    ModelBox,
    PrinterBox,
    Axes,
    Grid,
} as const;

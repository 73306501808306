import uniqBy from 'lodash/uniqBy';
import { createSelector } from 'reselect';
import { RootState, DiscountTypes, InitialOrder, Order } from '@types';
import { selectIqtModeOn } from '../user';

export const selectOrderId = (state: RootState) => state.order.orderId;
export const selectOrderData = (state: RootState) => state.order.data;
export const selectOrderLoaded = (state: RootState) => Boolean(state.order.data?.id);
export const selectOrderLoadedOrAbsent = (state: RootState) => {
    const orderId = selectOrderId(state);
    const orderLoaded = selectOrderLoaded(state);
    return !orderId || orderLoaded;
};

export const selectOrderForm = (state: RootState) => state.order.form;
export const selectAdditionalContactsList = (state: RootState) => state.order.additionalContactsList;
export const selectAdditionalContactsFormAttributes = (state: RootState) =>
    state.order.additionalContactsFormAttributes;
export const selectOrderCreatingPayload = (state: RootState) => state.order.payloadPassthrough;
export const selectIsOrderCreating = (state: RootState) => state.order.isCreating;
export const selectIsOrderLoading = (state: RootState) => state.order.isLoading;
export const selectIsOrderUpdating = (state: RootState) => state.order.isUpdating;
export const selectIsOrderSubmitting = (state: RootState) => state.order.isSubmitting;
export const selectIsQuestionnaireLoading = (state: RootState) => state.order.isQuestionnaireLoading;
export const selectOrderQuestionnaireSchema = (state: RootState) => state.order.questionnaireSchema;

export const getOrderDiscount = (data: InitialOrder | Order | undefined) => {
    if (!data || !data.applied_discount) {
        return undefined;
    }

    const { applied_discount, order_discount_in_percents } = data;

    const discountDetail = applied_discount.conditions[0];

    return {
        name: discountDetail.code,
        value: order_discount_in_percents,
        type: applied_discount.type,
        isPromo: applied_discount.type === DiscountTypes.Promo,
    };
};

export type OrderDiscountData = NonNullable<ReturnType<typeof getOrderDiscount>>;

export const selectOrderAppliedDiscount = createSelector(selectOrderData, getOrderDiscount);

export const selectOrderPromocodeError = (state: RootState) => state.order.promocodeError;

export const selectOutsideIqtOrderTbd = createSelector(selectOrderData, selectIqtModeOn, (data, iqtModeOn) => {
    if (!data || !data.tbd_reason || iqtModeOn) {
        return [];
    }
    const { tbd_reason, tbd_reason_message } = data;
    return [{ tbd_reason, tbd_reason_message }];
});

export const selectOutsideIqtProductsTbd = createSelector(selectOrderData, selectIqtModeOn, (data, iqtModeOn) => {
    if (!data?.products || iqtModeOn) {
        return [];
    }

    return data.products
        .map(({ id, tbd_reason, tbd_reason_message }) => ({ id, tbd_reason, tbd_reason_message }))
        .filter(({ tbd_reason }) => Boolean(tbd_reason));
});

export const selectWholeOrderTbd = createSelector(
    selectOutsideIqtOrderTbd,
    selectOutsideIqtProductsTbd,
    (cartTbd, productsTbd) => {
        return uniqBy([...cartTbd, ...productsTbd], ({ tbd_reason }) => tbd_reason);
    },
);

export const makeSelectOrderExactTriggersTbd = () =>
    createSelector(
        (state: RootState, productId: string) => productId,
        selectOutsideIqtOrderTbd,
        selectOutsideIqtProductsTbd,
        (productId, cartTbd, productsTbd) => {
            const currentTbd = productsTbd.find(({ id }) => id === productId);

            if (!currentTbd) {
                return cartTbd;
            }

            return [...cartTbd, currentTbd];
        },
    );

import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { AppEpic, LoadClientsParams } from '@types';
import { getPaginationRequestParams } from '@components/pagination';
import { ClientsPageSize } from '@pages/iqt/client/constants';
import { ClientService } from '@services';
import { clientsActions } from './slice';

const loadClientsEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(clientsActions.load.match),
        switchMap(action => {
            const params: LoadClientsParams = {
                ...getPaginationRequestParams(action.payload.page, ClientsPageSize),
                ordering: '-date_created',
            };

            if (action.payload.search) {
                params.search = action.payload.search;
            }

            return from(ClientService.init().loadClients(params)).pipe(
                map(({ data }) => clientsActions.loadSuccess(data)),
                catchError(() => of(clientsActions.loadFailure())),
            );
        }),
    );

export const clientsEpics = combineEpics(loadClientsEpic);

import { combineSlices } from '@reduxjs/toolkit';

import { appReducer } from '@modules/app';
import { askForHelpSlice } from '@modules/ask-for-help';
import { authReducer } from '@modules/auth';
import { clientReducer } from '@modules/client';
import { clientsSlice } from '@modules/clients';
import { deliveryRatesSlice } from '@modules/delivery-rates';
import { invoiceSlice } from '@modules/invoice';
import { viewerSlice } from '@modules/model-viewer';
import { modelsReducer } from '@modules/models';
import { modelsPageSlice } from '@modules/models-page';
import { ordersSlice } from '@modules/orders';
import { postProductionPricingSlice } from '@modules/post-production';
import { priceCorrectionsSlice } from '@modules/price-corrections';
import { technologiesSlice } from '@modules/technologies';
import { uiReducer } from '@modules/ui';
import { uploadModelsReducer } from '@modules/upload-models';
import { userSlice } from '@modules/user';
import { orderReducer } from '@modules/order';
import { orderPageSlice } from '@modules/order-page';
import { pricingSlice } from '@modules/pricing';
import { productSlice } from '@modules/product';
import { wallThicknessSlice } from '@modules/wall-thickness';
import { quotationSlice } from '@modules/quotation';
import { preselectionSlice } from '@modules/preselection';
import { suitableMaterialsSlice } from '@modules/suitable-materials';
// import { dfApi } from '@services/df/baseApi';

/*
    Most often, queries are enough to solve a problem, but if there is logic relating to different components,
    complex async flow or you need data persistence at the browser level, then you can create a slice.

    Root reducer structure:

        export const rootReducer = combineSlices({
            domain/namespace: combineReducers({
                namespaceFeature1: reducer,
                namespaceFeature2: reducer,
                ...
            }),
            bigFeature: combineReducers({
                bigFeaturePart1: reducer,
                bigFeaturePart2: reducer,
                ...
            }),
            generic/uniqueFeature: reducer,
            ...
        });

    Maximum nesting depth is 2, if you need more, try to modularize reducer:
        1) https://www.reddit.com/r/reactjs/comments/166dl4x/comment/jyjfa8e/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button

    Slice naming convention:
        1) for single reducer 'uniqueFeature'
        2) for nested 'namespace/reducer'
 */

export const rootReducer = combineSlices({
    app: appReducer,
    askForHelp: askForHelpSlice.reducer,
    auth: authReducer,
    client: clientReducer,
    clients: clientsSlice.reducer,
    deliveryRates: deliveryRatesSlice.reducer,
    modelViewer: viewerSlice.reducer,
    models: modelsReducer,
    modelsPage: modelsPageSlice.reducer,
    invoice: invoiceSlice.reducer,
    order: orderReducer,
    orderPage: orderPageSlice.reducer,
    orders: ordersSlice.reducer,
    postProductionPricing: postProductionPricingSlice.reducer,
    product: productSlice.reducer,
    pricing: pricingSlice.reducer,
    priceCorrections: priceCorrectionsSlice.reducer,
    preselection: preselectionSlice.reducer,
    quotation: quotationSlice.reducer,
    technologies: technologiesSlice.reducer,
    suitableMaterials: suitableMaterialsSlice.reducer,
    uploadModels: uploadModelsReducer,
    ui: uiReducer,
    user: userSlice.reducer,
    wallThickness: wallThicknessSlice.reducer,
    // [dfApi.reducerPath]: dfApi.reducer,
});

import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import isUndefined from 'lodash/isUndefined';
import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';
import {
    AppThunk,
    Technology,
    RepeatedSpecification,
    ModelSpecification,
    ModelSpecifications,
    ProductSpecifications,
    PriceConfigForm,
    SetPriceConfigActionMeta,
    Product,
    InvalidateLevel,
    MaterialSelectionMode,
} from '@types';
import { ModelViewerRenderMode } from '@utils';
import { appActions } from '@modules/app';

interface State {
    count: number; // calculation box counter
    visibleModels: number[]; // list of visible parent-model's ids
    viewedModels: number[]; // list of viewed parent-model's ids
    currentModelId?: number; // parent model ID
    currentProduct?: Product; // the product we are editing
    repeatedSpecification?: RepeatedSpecification;
    specifications: Record<string, ModelSpecifications>; // parent model ID : specification
    productsSpecifications: Record<string, ProductSpecifications>; // product ID : specification
    preselectionModeOn: boolean;
    repeatSpecsModeOn: boolean;
    modelIsRendered: boolean;
    showThinWalls: boolean;
    currentViewerRenderMode: ModelViewerRenderMode;
    fullPreselectionModeOn: boolean;
}

const initialState: State = {
    count: 1,
    visibleModels: [],
    viewedModels: [],
    specifications: {},
    productsSpecifications: {},
    repeatSpecsModeOn: false,
    preselectionModeOn: true,
    fullPreselectionModeOn: false,
    modelIsRendered: false,
    showThinWalls: false,
    currentViewerRenderMode: ModelViewerRenderMode.Solid,
};

function mergeRepeatedSpecificationConfig(prev: PriceConfigForm, next: PriceConfigForm) {
    // Our goal is to copy prev config values if they exist in next

    return (Object.keys(next) as Array<keyof PriceConfigForm>).reduce((acc, key) => {
        // features depend on model, so we don't repeat them when switching between models
        if (isUndefined(prev[key]) || key === 'features') {
            return {
                ...acc,
                [key]: next[key],
            };
        }

        return {
            ...acc,
            [key]: prev[key],
        };
    }, {} as PriceConfigForm);
}

function mergeSpecification(
    state: State,
    target: 'specifications' | 'productsSpecifications',
    entityId: string | number,
    spec: Omit<ModelSpecifications, 'correctObjectModels'>,
): Omit<ModelSpecifications, 'correctObjectModels'> {
    const specifications = state[target];
    return {
        ...specifications[entityId],
        ...spec, // technologyId
        ...(spec.materialIds
            ? {
                  materialIds: {
                      ...specifications[entityId]?.materialIds,
                      ...spec.materialIds,
                  },
              }
            : {}),
        ...(spec.priceConfigs
            ? {
                  priceConfigs: {
                      ...specifications[entityId]?.priceConfigs,
                      ...spec.priceConfigs,
                  },
              }
            : {}),
    };
}

function mergeModelSpecification(state: State, modelId: string | number, spec: ModelSpecifications = {}) {
    return {
        ...mergeSpecification(state, 'specifications', modelId, spec),
        ...(spec.correctObjectModels
            ? {
                  correctObjectModels: {
                      ...state.specifications[modelId]?.correctObjectModels,
                      ...spec.correctObjectModels,
                  },
              }
            : {}),
    };
}

function updateModelSpecification(state: State, modelId?: string | number, spec?: ModelSpecifications) {
    return !modelId || !spec
        ? state.specifications
        : {
              ...state.specifications,
              [modelId]: mergeModelSpecification(state, modelId, spec),
          };
}

function mergeProductSpecification(state: State, productId: string, spec: Partial<ProductSpecifications>) {
    return mergeSpecification(state, 'productsSpecifications', productId, spec) as ProductSpecifications;
}

function updateProductSpecification(state: State, productId: string, spec: Partial<ProductSpecifications>) {
    return {
        ...state.productsSpecifications,
        [productId]: mergeProductSpecification(state, productId, spec),
    };
}

function _setProduct(state: Draft<State>, product: Product) {
    state.currentModelId = product.parent_model_id;
    state.currentProduct = product;
    state.count = product.count;
}

function _resetProduct(state: Draft<State>) {
    state.currentProduct = undefined;
    state.count = 1;
}

function _rewriteRepeatSpecification(state: Draft<State>, modelId: number) {
    if (!state.repeatSpecsModeOn) return;

    if (state.repeatedSpecification) {
        // set tech and material from repeat spec for the modelId, but reset config,
        // config will be merged with the following action (quotationActions.setConfig)
        state.specifications = updateModelSpecification(state, modelId, {
            technologyId: state.repeatedSpecification.technologyId,
            materialIds: {
                [state.repeatedSpecification.technologyId]: state.repeatedSpecification.materialId,
            },
            priceConfigs: { [state.repeatedSpecification.materialId]: undefined },
        });
    } else {
        // if (process.env.NODE_ENV !== 'production') {
        //     throw new Error('repeatedSpecification is not set!');
        // }
    }
}

export const quotationSlice = createSlice({
    name: 'quotation',
    initialState,
    reducers: {
        defineWidgetObjects: (
            state,
            action: PayloadAction<{
                product?: Product;
                modelId?: number;
                hideModelId?: number;
                extendVisibleModels?: number[] | number;
                extendViewedModels?: number[] | number;
            }>,
        ) => {
            const stateUpdates: Partial<State> = {};

            // const needRewriteSpecification = action.payload.modelId && !action.payload.product;
            // if (needRewriteSpecification) {
            //     const modelId = action.payload.modelId!;
            //     _rewriteRepeatSpecification(state, modelId);
            // }

            if (action.payload.modelId) {
                stateUpdates['currentModelId'] = action.payload.modelId;
            }

            if (action.payload.extendVisibleModels) {
                const visibleModels = stateUpdates['visibleModels'] || state.visibleModels;
                stateUpdates['visibleModels'] = uniq(visibleModels.concat(action.payload.extendVisibleModels));
            }

            if (action.payload.extendViewedModels) {
                stateUpdates['viewedModels'] = uniq(state.viewedModels.concat(action.payload.extendViewedModels));
            }

            if (action.payload.product) {
                stateUpdates['currentProduct'] = action.payload.product;
                stateUpdates['count'] = action.payload.product.count;
            }

            return {
                ...state,
                ...stateUpdates,
            };
        },
        setCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setProduct: (state, action: PayloadAction<Product>) => {
            _setProduct(state, action.payload);
        },
        setModel: (state, action: PayloadAction<number>) => {
            state.viewedModels = uniq(state.viewedModels.concat(action.payload));
            state.currentModelId = action.payload;
            state.currentProduct = undefined;

            _rewriteRepeatSpecification(state, action.payload);
        },
        showModel: (state, action: PayloadAction<number>) => {
            state.visibleModels = uniq(state.visibleModels.concat(action.payload));
            state.currentModelId = action.payload;
            state.currentProduct = undefined;

            _rewriteRepeatSpecification(state, action.payload);
        },
        hideModel: (
            state,
            action: PayloadAction<{
                modelId: number;
                modelProduct?: Product;
            }>,
        ) => {
            state.visibleModels = state.visibleModels.filter(id => id !== action.payload.modelId);

            const isCurrentModel = state.currentModelId === action.payload.modelId;
            if (action.payload.modelProduct && !state.currentProduct && isCurrentModel) {
                _setProduct(state, action.payload.modelProduct);
            }
        },
        replaceModel: (
            state,
            action: PayloadAction<{
                prevId: number;
                nextId: number;
            }>,
        ) => {
            const ids = state.visibleModels.slice();
            ids.splice(ids.indexOf(action.payload.prevId), 1, action.payload.nextId);
            state.visibleModels = uniq(ids);
            state.currentModelId = action.payload.nextId;
        },
        resetCurrentModel: state => {
            state.visibleModels = state.visibleModels.filter(id => id !== state.currentModelId);
            state.currentModelId = undefined;
            _resetProduct(state);
        },
        resetCurrentProduct: state => {
            _resetProduct(state);
        },
        setTechnology: (state, action: PayloadAction<Technology>) => {
            const technologyId = action.payload.id;
            const materialId = action.payload.materials[0].id;
            const repeatSpecsModeOn = state.repeatSpecsModeOn;

            if (state.currentProduct) {
                return {
                    ...state,
                    productsSpecifications: updateProductSpecification(state, state.currentProduct.id, {
                        technologyId,
                    }),
                };
            }

            return {
                ...state,
                ...(repeatSpecsModeOn
                    ? {
                          repeatedSpecification: {
                              technologyId,
                              materialId,
                          },
                      }
                    : {}),
                specifications: updateModelSpecification(state, state.currentModelId, {
                    technologyId,
                    ...(repeatSpecsModeOn
                        ? {
                              priceConfigs: {
                                  [materialId]: undefined,
                              },
                              materialIds: {
                                  [technologyId]: materialId,
                              },
                          }
                        : {}),
                }),
            };
        },
        setMaterial: (state, action: PayloadAction<number>) => {
            const materialId = action.payload;

            if (state.currentProduct) {
                return {
                    ...state,
                    productsSpecifications: updateProductSpecification(state, state.currentProduct.id, {
                        materialIds: {
                            // we make sure setTechnology is called before setMaterial
                            [state.productsSpecifications[state.currentProduct.id].technologyId!]: materialId,
                        },
                    }),
                };
            }

            const repeatSpecsModeOn = state.repeatSpecsModeOn;

            return {
                ...state,
                ...(repeatSpecsModeOn && state.repeatedSpecification
                    ? {
                          repeatedSpecification: {
                              technologyId: state.repeatedSpecification.technologyId,
                              materialId,
                          },
                      }
                    : {}),
                specifications: state.currentModelId
                    ? updateModelSpecification(state, state.currentModelId, {
                          materialIds: {
                              [state.specifications[state.currentModelId].technologyId!]: materialId,
                          },
                          ...(repeatSpecsModeOn
                              ? {
                                    priceConfigs: {
                                        ...state.specifications[state.currentModelId].priceConfigs,
                                        [materialId]: undefined,
                                    },
                                }
                              : {}),
                      })
                    : state.specifications,
            };
        },
        setConfig: {
            reducer(state, action: PayloadAction<PriceConfigForm, string, SetPriceConfigActionMeta>) {
                let priceConfig = action.payload;

                if (state.currentProduct) {
                    const spec = state.productsSpecifications[state.currentProduct.id];
                    return {
                        ...state,
                        productsSpecifications: updateProductSpecification(state, state.currentProduct.id, {
                            priceConfigs: {
                                // we make sure setMaterial is called before setConfig
                                [spec.materialIds![spec.technologyId!]!]: priceConfig,
                            },
                        }),
                    };
                }

                const repeatSpecsModeOn = state.repeatSpecsModeOn;
                const repeatedSpecificationConfig = state.repeatedSpecification?.priceConfig;

                if (repeatSpecsModeOn && action.meta.mergeRepeatSpecs && repeatedSpecificationConfig) {
                    priceConfig = mergeRepeatedSpecificationConfig(repeatedSpecificationConfig, priceConfig);
                }

                return {
                    ...state,
                    ...(repeatSpecsModeOn && state.repeatedSpecification
                        ? {
                              repeatedSpecification: {
                                  ...state.repeatedSpecification,
                                  priceConfig,
                              },
                          }
                        : {}),
                    specifications: state.currentModelId
                        ? updateModelSpecification(state, state.currentModelId, {
                              priceConfigs: {
                                  [state.specifications[state.currentModelId].materialIds![
                                      state.specifications[state.currentModelId].technologyId!
                                  ]!]: priceConfig,
                              },
                          })
                        : state.specifications,
                };
            },
            prepare(payload: PriceConfigForm, meta: SetPriceConfigActionMeta) {
                return { payload, meta };
            },
        },
        setCorrectObjectModel: (
            state,
            action: PayloadAction<{
                model_id: number;
                technology_id: number;
                correct_model_id: number;
            }>,
        ) => {
            state.specifications = updateModelSpecification(state, action.payload.model_id, {
                correctObjectModels: {
                    [action.payload.technology_id]: action.payload.correct_model_id,
                },
            });
        },

        setRepeatedSpecification: (state, action: PayloadAction<RepeatedSpecification>) => {
            state.repeatedSpecification = action.payload;
        },
        setSpecifications: (state, action: PayloadAction<Record<string, ModelSpecifications>>) => {
            state.specifications = {
                ...state.specifications,
                ...action.payload,
            };
        },
        updateSpecifications: (state, action: PayloadAction<Record<string, ModelSpecifications>>) => {
            state.specifications = {
                ...state.specifications,
                ...Object.entries(action.payload).reduce(
                    (acc, [modelId, spec]) => ({ ...acc, [modelId]: mergeModelSpecification(state, modelId, spec) }),
                    {},
                ),
            };
        },
        setProductsSpecifications: (state, action: PayloadAction<Record<string, ProductSpecifications>>) => {
            state.productsSpecifications = {
                ...state.productsSpecifications,
                ...action.payload,
            };
        },
    },
    extraReducers: builder => {
        builder.addCase(appActions.initAppSuccess, (state, { payload }) => ({
            ...state,
            preselectionModeOn:
                payload.material_selection_mode === MaterialSelectionMode.Preselection ||
                payload.material_selection_mode === MaterialSelectionMode.FullPreselection,
            repeatSpecsModeOn: payload.material_selection_mode === MaterialSelectionMode.RepeatSpecs,
            fullPreselectionModeOn: payload.material_selection_mode === MaterialSelectionMode.FullPreselection,
        }));
        builder.addCase(appActions.invalidateStore, (state, { payload }) =>
            payload.purge >= InvalidateLevel.Models
                ? {
                      ...initialState,
                      fullPreselectionModeOn: state.fullPreselectionModeOn,
                      preselectionModeOn: state.preselectionModeOn,
                      repeatSpecsModeOn: state.repeatSpecsModeOn,
                      repeatedSpecification: omit(state.repeatedSpecification, ['priceConfig']),
                  }
                : state,
        );
    },
});

export const quotationActions = quotationSlice.actions;

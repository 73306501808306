import cn from 'classnames';
import React, { useState } from 'react';
import { TFunction } from 'i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { NavLink } from 'react-router-dom';
import { DropdownMenu, MenuItem, MenuItemLink } from '@react-md/menu';
import { BELOW_CENTER_ANCHOR, Button, TextIconSpacing } from 'react-md';
import { Icons } from '@components/icons';
import { FeatureContent, FeatureMarkup } from '@components/feature-content';
import { InvalidateLevel } from '@types';
import { appActions } from '@modules/app';
import { selectAuthorizedUser } from '@modules/user';
import { useBuildLocation } from '@hooks';
import { reverse } from '@utils';
import { isHeaderLink, _id, menuId, isHeaderButton } from './helpers';
import { HeaderActions } from './types';
import { AdminMenuActions, AnonMenuActions, AuthMenuActions, HeaderMenuAction } from './constants';

import styles from './header.module.scss';

const useAuxiliaryState = () => {
    const [howItWorksIsOpen, setHowItWorksOpen] = useState(false);

    return {
        howItWorksIsOpen,
        setHowItWorksOpen,
    };
};

interface Props {
    isAuthenticated: boolean;
    isOwner: boolean;
    isDesktop: boolean;
    t: TFunction;
}

export const useHeaderActions = ({ isAuthenticated, isOwner, isDesktop, t }: Props) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectAuthorizedUser);

    const ordersLocation = useBuildLocation({
        to: reverse('userOrders'),
    });
    // const settingsLocation = useBuildLocation({
    //     to: reverse('userSettings'),
    // });
    const modelsLocation = useBuildLocation({
        to: reverse('userModels'),
        setFromLocation: true, // todo for back button? need to check
    });
    const signInLocation = useBuildLocation({
        to: reverse('signIn'),
    });
    const signupLocation = useBuildLocation({
        to: reverse('signUp'),
    });

    const auxiliaryState = useAuxiliaryState();

    const actions: HeaderActions = {
        myAccount: {
            id: menuId('my_account'),
            label: <FeatureContent contentKey={menuId('my_account')} fallback={t('buttons.myAccount', 'My account')} />,
            icon: <Icons.Settings />,
            button: (
                <FeatureContent
                    contentKey={menuId('my_account')}
                    fallback={t('buttons.myAccount', 'My account')}
                    wrapper={props => (
                        <DropdownMenu
                            id={props.id!}
                            className={cn('rmd-button--flat', styles.navLink)}
                            menuClassName={styles.menu}
                            onClick={actions.myAccount.onClick}
                            children={accountActions}
                            buttonChildren={
                                <TextIconSpacing icon={actions.myAccount.icon} forceIconWrap>
                                    {props.children}
                                </TextIconSpacing>
                            }
                            anchor={BELOW_CENTER_ANCHOR}
                            disableRipple
                            portal
                        />
                    )}
                />
            ),
        },
        howItWorks: {
            id: menuId('how_it_works'),
            label: (
                <FeatureContent
                    contentKey={menuId('how_it_works')}
                    fallback={t('buttons.howItWorks', 'How it works')}
                />
            ),
            icon: <Icons.PlayCircle />,
            onClick: () => auxiliaryState.setHowItWorksOpen(true),
            button: (
                <FeatureContent
                    contentKey={menuId('how_it_works')}
                    fallback={t('buttons.howItWorks', 'How it works')}
                    wrapper={props => (
                        <Button
                            className={cn('rmd-button--flat', styles.navLink)}
                            disableRipple
                            onClick={actions.howItWorks.onClick}
                            {...props}
                        >
                            <TextIconSpacing icon={actions.howItWorks.icon} forceIconWrap>
                                {props.children}
                            </TextIconSpacing>
                        </Button>
                    )}
                />
            ),
        },
        newQuote: {
            id: menuId('get_new_quote'),
            label: (
                <FeatureContent
                    contentKey={menuId('get_new_quote')}
                    fallback={t('buttons.newQuote', 'Get a new quote')}
                />
            ),
            icon: <Icons.Box />,
            button: (
                <FeatureContent
                    contentKey={menuId('get_new_quote')}
                    fallback={t('buttons.newQuote', 'Get a new quote')}
                    wrapper={props => (
                        <Button
                            className={cn('rmd-button--flat', styles.navLink)}
                            disableRipple
                            onClick={actions.newQuote.onClick}
                            {...props}
                        >
                            <TextIconSpacing icon={actions.newQuote.icon} forceIconWrap>
                                {props.children}
                            </TextIconSpacing>
                        </Button>
                    )}
                />
            ),
            onClick: () =>
                dispatch(
                    appActions.invalidateStore({
                        purge: InvalidateLevel.Order,
                        redirect: reverse('widgetUpload'),
                        redirectByRouter: true,
                    }),
                ),
        },
        models: {
            id: menuId('models'),
            label: <FeatureContent contentKey={menuId('models')} fallback={t('links.models', 'Models')} />,
            link: modelsLocation,
        },
        email: {
            id: menuId('email'),
            label: <span>{user?.email}</span>,
            className: 'rmd-list-item--email',
        },
        // settings: {
        //     id: menuId('settings'),
        //     label: <FeatureContent contentKey={menuId('settings')} fallback={t('links.settings', 'Settings')} />,
        //     link: settingsLocation,
        // },
        orders: {
            id: menuId('orders'),
            label: <FeatureContent contentKey={menuId('orders')} fallback={t('links.orders', 'Quotes & Orders')} />,
            link: ordersLocation,
        },
        themeConfig: {
            id: menuId('theme_config'),
            label: (
                <FeatureContent contentKey={menuId('theme_config')} fallback={t('buttons.editTheme', 'Edit theme')} />
            ),
            onClick: () => {
                dispatch(appActions.toggleThemeConfigIsOpened());
            },
        },
        contentEditableMode: {
            id: menuId('content_editable_mode'),
            label: <span>{t('buttons.editTexts', 'Edit texts')}</span>,
            onClick: () => {
                dispatch(appActions.toggleContentEditableMode());
            },
        },
        logout: {
            id: menuId('logout'),
            label: <FeatureContent contentKey={menuId('logout')} fallback={t('buttons.logout', 'Log out')} />,
            onClick: () =>
                dispatch(
                    appActions.invalidateStore({
                        purge: InvalidateLevel.Full,
                        redirect: reverse('signIn'),
                    }),
                ),
        },
        signIn: {
            id: menuId('sign_in'),
            label: <FeatureContent contentKey={menuId('sign_in')} fallback={t('buttons.signIn', 'Sign in')} />,
            link: signInLocation,
        },
        signUp: {
            id: menuId('sign_up'),
            label: <FeatureContent contentKey={menuId('sign_up')} fallback={t('buttons.signUp', 'Sign up')} />,
            link: signupLocation,
        },
    };

    const AuthMenuActionsPermissionTested = isOwner
        ? [...AuthMenuActions, ...AdminMenuActions, HeaderMenuAction.Logout]
        : [...AuthMenuActions, HeaderMenuAction.Logout];

    const accountActions = (isAuthenticated ? AuthMenuActionsPermissionTested : AnonMenuActions).map(actionName => {
        const action = actions[actionName];
        const className = isDesktop ? action.className : styles.mobileItem;

        let actionItem = null;
        if (isHeaderLink(action)) {
            actionItem = (
                <MenuItemLink
                    key={action.id}
                    id={action.id}
                    component={NavLink}
                    className={className}
                    style={({ isActive }: { isActive: boolean }) => ({
                        pointerEvents: isActive ? 'none' : 'auto',
                        opacity: isActive ? 0.6 : 1,
                    })}
                    {...action.link}
                >
                    {action.label}
                </MenuItemLink>
            );
        } else if (isHeaderButton(action)) {
            actionItem = (
                <MenuItem key={action.id} id={action.id} onClick={action.onClick} className={className}>
                    {action.label}
                </MenuItem>
            );
        } else {
            actionItem = (
                <MenuItem key={action.id} id={action.id} disabled className={className}>
                    {action.label}
                </MenuItem>
            );
        }

        return actionItem;
    });

    const accountActionsMobile = [
        <MenuItem
            key={actions.myAccount.id}
            id={actions.myAccount.id}
            className={cn('rmd-typography--semi-bold', styles.mobileItemBig)}
            disabled
            leftAddon={actions.myAccount.icon}
        >
            {actions.myAccount.label}
        </MenuItem>,

        ...accountActions,

        <MenuItem
            key={actions.newQuote.id}
            id={actions.newQuote.id}
            className={cn(
                'small-margin-top',
                'rmd-typography--semi-bold',
                'rmd-typography--secondary',
                styles.mobileItemBig,
            )}
            leftAddon={actions.newQuote.icon}
            onClick={actions.newQuote.onClick}
        >
            {actions.newQuote.label}
        </MenuItem>,
        <MenuItem
            key={actions.howItWorks.id}
            id={actions.howItWorks.id}
            className={cn(
                'small-margin-top',
                'rmd-typography--semi-bold',
                'rmd-typography--secondary',
                styles.mobileItemBig,
            )}
            leftAddon={actions.howItWorks.icon}
            onClick={actions.howItWorks.onClick}
        >
            {actions.howItWorks.label}
        </MenuItem>,

        <FeatureMarkup
            key="custom_item_markup"
            contentKey={menuId('custom_item_markup')}
            wrapper={props => <MenuItem className={cn('small-margin-top', styles.mobileItem)} {...props} />}
            isEditable={false}
        />,
    ];

    return { actions, accountActions, accountActionsMobile, auxiliaryState };
};

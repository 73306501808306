import * as Three from 'three';
import type { Box3 } from 'three';
import { ModelViewerVariables } from '../constants';

export class ModelBoundingBox extends Three.Box3Helper {
    constructor(box: Box3) {
        super(box, new Three.Color(ModelViewerVariables.ModelBox.Color));
        this.renderOrder = 6;
    }
}

import { OrderForm, ShippingTypes, AppSettings, FormDataType, FieldGroups } from '@types';
import { extractFields, filterEmptyValues, mergeFormIntoData } from '@utils';

export const filterOrderCreationData = (
    form: OrderForm,
    settings: AppSettings,
    customFields?: FieldGroups[],
    additionalContactsList?: string[],
): FormDataType => {
    const { isCompanyOrder, shippingType } = form;

    const fields = {
        name: form.first_name,
        surname: form.last_name,
        phone: form.phone,
        email: form.email,
        notes: form.notes,
        status: form.status,
    };

    if (isCompanyOrder) {
        Object.assign(fields, {
            billing_name: form.billing_name,
            billing_surname: form.billing_surname,
            billing_phone: form.billing_phone,
            billing_email: form.billing_email,
        });
    }

    const customFieldsData = mergeFormIntoData(form, extractFields(customFields));

    const isDeliveryAddressRequired =
        Boolean(shippingType === ShippingTypes.Delivery) ||
        (Boolean(shippingType === ShippingTypes.Pickup) && settings.require_address_on_pickup);

    return {
        ...filterEmptyValues(fields),
        ...(customFieldsData && { custom_fields: JSON.stringify(customFieldsData) }),
        ...(additionalContactsList &&
            additionalContactsList.length !== 0 && { additional_billing_contacts: additionalContactsList }),
        ...(isCompanyOrder && { customer_company_address: JSON.stringify(filterEmptyValues(form.billing_address)) }),
        ...(isDeliveryAddressRequired && {
            delivery_address: JSON.stringify(filterEmptyValues(form.delivery_address)),
        }),
    };
};

import { PaginationConfig } from '@constants';

export function getTotalNumPages(totalCount: number, pageSize: number = PaginationConfig.pageSize) {
    return Math.ceil(totalCount / pageSize);
}

export function getPaginationOffset(pageNumber: number, pageSize: number = PaginationConfig.pageSize) {
    return Math.max(pageNumber - 1, 0) * pageSize;
}

export function getPaginationRequestParams(pageNumber: number, pageSize: number = PaginationConfig.pageSize) {
    return {
        limit: pageSize,
        offset: getPaginationOffset(pageNumber, pageSize),
    };
}

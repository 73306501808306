import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createPersistReducer, PersistReducerConfigs } from '@app/persist';
import {
    ClientRepresentationDetailedSerializer,
    ClientRepresentationSerializer,
    CreateClientForm,
    FormOnSubmit,
    StoreResponseErrors,
    InvalidateLevel,
} from '@types';
import { appActions } from '../app/slice';

export interface ClientReducerState {
    clientId?: number;
    data?: ClientRepresentationSerializer | ClientRepresentationDetailedSerializer;
    isLoading: boolean;
    isCreating: boolean;
}

const initialState: ClientReducerState = { isLoading: false, isCreating: false };

export const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setClientId: (state, action: PayloadAction<number | undefined>) => {
            state.clientId = action.payload;
        },

        load: (state, action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        loadSuccess: (state, action: PayloadAction<ClientRepresentationDetailedSerializer>) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        loadFailure: state => {
            state.isLoading = false;
        },

        create: (state, action: PayloadAction<FormOnSubmit<CreateClientForm>>) => {
            state.isCreating = true;
        },
        createSuccess: (state, action: PayloadAction<ClientRepresentationSerializer>) => {
            state.isCreating = false;
            state.data = action.payload;
            state.clientId = action.payload.id;
        },
        createFailure: (state, action: PayloadAction<StoreResponseErrors>) => {
            state.isCreating = false;
        },
    },
    extraReducers: builder => {
        builder.addCase(appActions.invalidateStore, (state, { payload }) =>
            payload.purge >= InvalidateLevel.Client ? { ...initialState } : state,
        );
    },
});

export const clientReducer = createPersistReducer(PersistReducerConfigs.client, clientSlice.reducer);
export const clientActions = clientSlice.actions;

import { from, of } from 'rxjs';
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { AppEpic } from '@types';
import { ClientService } from '@services';
import { userActions } from './slice';

const loadUserEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(userActions.load.match),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            from(ClientService.init().getClientInfo()).pipe(
                switchMap(({ data }) => of(userActions.loadSuccess(data))),
                catchError(error => of(userActions.loadFailure())),
            ),
        ),
    );

export const userEpics = combineEpics(loadUserEpic);

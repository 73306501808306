import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { DateTransformType } from '@constants';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

interface DateTransformProps {
    dateString: string | undefined;
    transformTo?: DateTransformType;
}

export const DateTransform: React.FC<DateTransformProps> = ({ dateString, transformTo = DateTransformType.Format }) => {
    const [formattedDate, setFormattedDate] = useState('');
    const { language } = i18n;

    useEffect(() => {
        const fetchDate = async () => {
            const date = await toFormattedDate({ dateString, language, format: transformTo });
            if (date) setFormattedDate(date);
            else setFormattedDate('');
        };

        fetchDate();
    }, [dateString, language, transformTo]);

    return <span>{formattedDate}</span>;
};

interface ToFormattedDate {
    dateString: string | undefined;
    language: string;
    format: DateTransformType;
}

async function toFormattedDate({ dateString, language, format }: ToFormattedDate) {
    if (!dateString) return '';
    try {
        const dayjsLocale = await import(`dayjs/locale/${language}.js`);
        return dayjs(dateString).utc().locale(dayjsLocale).format(format).replace(/[-/]/g, '.');
    } catch (error) {
        //***** Fallback for zh-hans language or other unintended cases of mismatching that can appear in the future *****/
        try {
            const mainLanguage = language.split('-')[0];
            const dayjsLocale = await import(`dayjs/locale/${mainLanguage}.js`);
            return dayjs(dateString).utc().locale(dayjsLocale).format(format).replace(/[-/]/g, '.');
        } catch {
            console.error(error);
            return dayjs(dateString).utc().format(format).replace(/[-/]/g, '.');
        }
    }
}

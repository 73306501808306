import React from 'react';
import cn from 'classnames';
import { ObjectModel } from '@types';
import { ModelSizes, ModelVolume } from '@modules/models';
import { useAppSelector } from '@app/hooks';
import { selectAppSettings } from '@modules/app';
import { Typography } from 'react-md';
import { DetailsBox } from './details-box';

import styles from './product-details.module.scss';

interface Props {
    model: ObjectModel;
}

export const ProductDetails: React.FC<Props> = ({ model }) => {
    const settings = useAppSelector(selectAppSettings);
    const defaultConvertedUnits = settings.display_widget_units || model.units;

    return (
        <DetailsBox className={cn(styles.box, 'fade-in')}>
            <div className={'flex flex-col items-start'}>
                <Typography type="headline-5" className={styles.title}>
                    {model.title}
                </Typography>

                <ModelSizes
                    size={model.size}
                    units={model.units}
                    convertTo={defaultConvertedUnits}
                    className="justify-center"
                />
                <ModelVolume model={model} convertTo={defaultConvertedUnits} className="justify-center" />
            </div>
        </DetailsBox>
    );
};

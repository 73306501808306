import { ValuesType } from './helpers';

export const UserRoleTypes = {
    CompanyOwner: 'company_owner',
    BlockedClient: 'blocked_client',
    Vendor: 'vendor',
    Salesman: 'salesman',
    SandboxOwner: 'sandbox_owner',
} as const;

export type UserRole = ValuesType<typeof UserRoleTypes>;
export type UserRoles = Array<UserRole>;

export interface User {
    id: number;
    email: string;
    name: string;
    surname: string;
    phone_number: string;
    position: string;
    company_name: string;
    roles: UserRoles;
    send_notification?: boolean;
}

import cn from 'classnames';
import { useEffect, ChangeEventHandler } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { wallThicknessActions, createWallThicknessPolling, selectWallThicknessResult } from '@modules/wall-thickness';
import {
    viewerActions,
    selectCurrentViewerRenderMode,
    selectModelIsRendered,
    selectShowThinWalls,
} from '@modules/model-viewer';
import { Switch } from '@react-md/form';
import { Typography } from '@react-md/typography';
import { Tooltipped } from '@react-md/tooltip';
import { Icons } from '@components/icons';
import { Alert, AlertHeading } from '@components/alert';
import { FeatureKeys as FF, useFeatureFlag } from '@components/feature-flags';
import { getNodeIdComposer, ModelViewerRenderMode } from '@utils';
import { ModelNotificationWallThicknessProps, WallThicknessNotPassedProps, WallThicknessPassedProps } from '../types';
import { AskForHelp } from '../../../ask-for-help';
import { ModelViewerPrefix } from '../../constants';

import styles from '../model-notifications.module.scss';

const _id = getNodeIdComposer(ModelViewerPrefix, 'wall_thickness');

export const ModelNotificationWallThickness = ({ model, materialId }: ModelNotificationWallThicknessProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const wallThicknessResult = useAppSelector(selectWallThicknessResult);

    const onlyFailedShown = useFeatureFlag(FF.CartPage.WallThicknessOnlyFailedShown);

    useEffect(() => {
        // start wall thickness polling
        model.id && materialId && dispatch(createWallThicknessPolling());

        // reset
        dispatch(viewerActions.showThinWallsModel(false));
    }, [dispatch, model.id, materialId]);

    if (!wallThicknessResult || (onlyFailedShown && wallThicknessResult.status !== 'not_passed')) {
        return null;
    }

    switch (wallThicknessResult.status) {
        case 'not_processed':
            return null;
        case 'passed':
            return <WallThicknessPassed t={t} />;
        case 'not_passed':
            return (
                <WallThicknessNotPassed
                    t={t}
                    model={model}
                    fileUrl={wallThicknessResult.thin_faces_file_url}
                    minimalWallThickness={wallThicknessResult.minimal_wall_thickness}
                />
            );
    }
};

const WallThicknessPassed = ({ t }: WallThicknessPassedProps) => {
    return (
        <Alert show className={styles.alert} variant="outlined" type="success">
            <AlertHeading customIcon={<Icons.CheckCircleTwo className="rmd-typography--theme-success" />}>
                <Typography type="body-2" component="span" className={styles.message}>
                    {t('messages.wallThicknessPassed', 'Wall thickness test passed')}
                </Typography>
            </AlertHeading>
        </Alert>
    );
};

const WallThicknessNotPassed = ({ model, minimalWallThickness, fileUrl, t }: WallThicknessNotPassedProps) => {
    const dispatch = useAppDispatch();
    const showThinWalls = useAppSelector(selectShowThinWalls);
    const modelIsRendered = useAppSelector(selectModelIsRendered);
    const currentViewerMode = useAppSelector(selectCurrentViewerRenderMode);

    const isReadyShowThinWalls = modelIsRendered && currentViewerMode === ModelViewerRenderMode.Solid;

    useEffect(() => {
        dispatch(wallThicknessActions.loadThinFaces({ url: fileUrl }));
    }, [dispatch, fileUrl]);

    const handleChangeSwitch: ChangeEventHandler<HTMLInputElement> = ({ target: { checked } }) => {
        dispatch(viewerActions.showThinWallsModel(checked));
    };

    return (
        <Alert show className={styles.alert} variant="outlined" type="error">
            <AlertHeading customIcon={<Icons.Slash className="rmd-typography--theme-error" />}>
                <div>
                    <div className={styles.message}>
                        <Typography type="body-1" component="span" className={styles.messageText}>
                            {t(
                                'messages.wallThicknessNotPassed',
                                'Walls are too thin, minimum recommended thickness value is {{minimal_wall_thickness}}',
                                {
                                    minimal_wall_thickness: minimalWallThickness,
                                },
                            )}
                        </Typography>
                        <Tooltipped
                            id={_id('hint')}
                            tooltip={
                                <Trans
                                    i18nKey="messages.wallThicknessAddNotif"
                                    defaults={
                                        'A wall thinner than {{minimal_wall_thickness}} mm will not be manufactured correctly, ' +
                                        'but will deform under its own weight or show up holes. However, you can place an order at your own risk. Or ask for help.'
                                    }
                                    values={{
                                        minimal_wall_thickness: minimalWallThickness,
                                    }}
                                />
                            }
                        >
                            <Icons.HelpCircle className={cn(styles.messageHelpIcon, 'rmd-typography--theme-primary')} />
                        </Tooltipped>
                    </div>
                    <Switch
                        id={_id('toggle_highlight')}
                        disabled={!isReadyShowThinWalls}
                        onChange={handleChangeSwitch}
                        checked={showThinWalls}
                        className={styles.switch}
                        ballClassName={styles.ball}
                        labelClassName={styles.switchLabel}
                        trackClassName={cn([styles.track, { [styles.trackActive]: showThinWalls }])}
                        label={
                            <Typography component="span" type="body-2">
                                {t('labels.showThinWalls', 'Show thin walls')}
                            </Typography>
                        }
                    />
                    <AskForHelp modelId={model.id} modelLabel={model.title} />
                </div>
            </AlertHeading>
        </Alert>
    );
};

import cn from 'classnames';
import React, { forwardRef, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useNavigate, To } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider } from '@react-md/divider';
import { Typography } from '@react-md/typography';
import { Label } from '@components/label';
import { Pagination } from '@components/pagination';
import { PreloaderBox } from '@components/preloader';
import { ClientRepresentationSerializer, InvalidateLevel } from '@types';
import { appActions } from '@modules/app';
import { clientActions, selectClientId } from '@modules/client';
import { selectClientsResults, selectClientsTotalCount, selectIsLoadingClients } from '@modules/clients';
import { ClientRow } from './client-row';
import { ClientsPageSize } from './constants';

import styles from './client-selection.module.scss';

interface ClientSelectionProps {
    to: To;
}

export default forwardRef<HTMLDivElement, ClientSelectionProps>(function ClientSelection({ to, ...props }, ref) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const data = useAppSelector(selectClientsResults);
    const totalCount = useAppSelector(selectClientsTotalCount);
    const isLoading = useAppSelector(selectIsLoadingClients);
    const storeClientId = useAppSelector(selectClientId);

    const { t } = useTranslation();

    const setClient = useCallback(
        (clientId: number) => {
            if (clientId !== storeClientId) {
                dispatch(
                    appActions.invalidateStore({
                        purge: InvalidateLevel.Order,
                    }),
                );
                dispatch(clientActions.setClientId(clientId));
                dispatch(clientActions.load(clientId));
            }

            navigate(to);
        },
        [dispatch, navigate, to, storeClientId],
    );

    return (
        <div {...props} ref={ref} className={cn('small-margin-top', styles.box)}>
            <PreloaderBox show={isLoading}>
                {data.length ? (
                    <div className={cn('overflow-x-auto', styles.table)}>
                        <table>
                            <thead className={styles.head}>
                                <tr>
                                    <td className={styles.cell}>
                                        <Label className={styles.label}>
                                            {t('labels.phoneNumber', 'Phone number')}
                                        </Label>
                                    </td>
                                    <td className={styles.cell}>
                                        <Label className={styles.label}>{t('labels.fullName', 'Full name')}</Label>
                                    </td>
                                    <td className={styles.cell}>
                                        <Label className={styles.label}>{t('labels.email', 'E-mail')}</Label>
                                    </td>
                                    <td className={styles.cell}>
                                        <Label className={cn(styles.label, 'rmd-typography--right')}>
                                            {t('labels.company', 'Company')}
                                        </Label>
                                    </td>
                                </tr>
                            </thead>

                            <tbody>
                                {data.map((client: ClientRepresentationSerializer) => {
                                    return (
                                        <ClientRow
                                            key={client.id}
                                            id={client.id}
                                            email={client.email}
                                            name={client.name}
                                            surname={client.surname}
                                            phone_number={client.phone_number}
                                            company_name={client.company_name}
                                            clientId={storeClientId}
                                            setClient={setClient}
                                        />
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <Typography type="body-1" component="div" className="margin-top">
                        {t('messages.noMatchingResults', 'No matching results')}
                    </Typography>
                )}
            </PreloaderBox>

            {totalCount > ClientsPageSize && (
                <div className={styles.bottom}>
                    <Divider className={cn('rmd-divider-no-gutters', styles.divider)} />
                    <Pagination totalCount={totalCount} pageSize={ClientsPageSize} />
                </div>
            )}
        </div>
    );
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModelViewerRenderMode } from '@utils';
import { RootState } from '@types';

interface State {
    modelIsRendered: boolean;
    showThinWalls: boolean;
    currentViewerRenderMode: ModelViewerRenderMode;
}

const initialState: State = {
    modelIsRendered: false,
    showThinWalls: false,
    currentViewerRenderMode: ModelViewerRenderMode.Solid,
};

export const viewerSlice = createSlice({
    name: 'viewer',
    initialState,
    reducers: {
        showThinWallsModel: (state, action: PayloadAction<boolean>) => {
            state.showThinWalls = action.payload;
        },
        setModelIsRendered: (state, action: PayloadAction<boolean>) => {
            state.modelIsRendered = action.payload;
        },
        setCurrentViewerRenderMode: (state, action: PayloadAction<ModelViewerRenderMode>) => {
            state.currentViewerRenderMode = action.payload;
        },
    },
});

export const viewerActions = viewerSlice.actions;

export const selectModelIsRendered = (state: RootState) => state.modelViewer.modelIsRendered;
export const selectShowThinWalls = (state: RootState) => state.modelViewer.showThinWalls;
export const selectCurrentViewerRenderMode = (state: RootState) => state.modelViewer.currentViewerRenderMode;

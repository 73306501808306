import cn from 'classnames';
import { memo, useEffect, useReducer, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useCloseOnEscape, useScrollLock, useToggle } from '@react-md/utils';
import { selectContentCustomization } from '@modules/app';
import { selectThinFaces } from '@modules/wall-thickness';
import { viewerActions, selectShowThinWalls } from '@modules/model-viewer';
import {
    getFileExtensionByUrl,
    ModelViewer as ModelViewerUtil,
    ModelViewerObjects,
    ModelViewerRenderMode,
} from '@utils';
import {
    FeatureKeys as FF,
    getFeatureFlag,
    getFeatureFlags,
    getObjectFeatureValue,
    ObjectsFeature,
} from '@components/feature-flags';
import { TechnologyMaterial } from '@types';
import { ModelExtensionsByFormat } from '@constants';
import { ViewerModePanel } from '../viewer-mode-panel';
import { ViewerThumb } from '../viewer-thumb';
import { ModelViewerDarkModeBox } from './model-viewer-dark-mode-box';
import { ModelViewerFullscreenTopPanel } from './model-viewer-fullscreen-top-panel';
import { ModelViewerFullscreenBottomPanel } from './model-viewer-fullscreen-bottom-panel';
import { ModelViewerSceneControlsPanel } from './model-viewer-scene-controls-panel';
import { viewerPropsAreEqual } from '../helpers';
import { ObjectModelModel } from '@models';
import { ModelViewerRotationDescription } from './model-viewer-rotation-description';

import styles from '../viewer-window.module.scss';

export interface ModelViewerProps {
    correctObjectModelId?: number;
    model: ObjectModelModel;
    material?: TechnologyMaterial;
    onLoadFileCallback: (modelFileUrl: string) => void;
}

export const ModelViewer = memo<ModelViewerProps>(({ correctObjectModelId, model, material, onLoadFileCallback }) => {
    let visualizer: HTMLDivElement;
    const dispatch = useAppDispatch();
    const thinModelFaces = useAppSelector(selectThinFaces);
    const showThinWalls = useAppSelector(selectShowThinWalls);
    const contentCustomization = useAppSelector(selectContentCustomization);
    const lastRenderedModelId = useRef<number>();
    const modelViewer = useRef<ModelViewerUtil>();
    const [currentMode, setCurrentMode] = useState<ModelViewerRenderMode>(ModelViewerRenderMode.Solid);
    const [isRotationModeActive, enterRotationMode, exitRotationMode] = useToggle(false);
    const [isFullscreenModeActive, enterFullscreenMode, exitFullscreenMode, toggleFullscreenMode] = useToggle(false);
    const [isThumbModeOn, enterThumbMode, exitThumbMode, toggleThumbMode] = useToggle(false);
    const [isDarkModeActive, toggleDarkMode] = useReducer(
        previous => !previous,
        undefined,
        () => getFeatureFlag(contentCustomization, FF.Viewer.DarkModeOn),
    );
    const { id: modelId, title, object_model_original_url, file_model_viewer_url, allowToRenderViewer } = model;

    useScrollLock(isFullscreenModeActive);

    useEffect(() => {
        modelViewer.current?.requestRenderIfNotRequested();

        if (!isFullscreenModeActive) {
            exitRotationMode();
        }
    }, [isFullscreenModeActive, exitRotationMode]);

    useEffect(() => {
        if (isRotationModeActive) {
            setCurrentMode(ModelViewerRenderMode.Solid);
            enterFullscreenMode();
            exitThumbMode();
        } else {
            exitFullscreenMode();
        }

        if (modelViewer.current) {
            modelViewer.current.rotateModeOn = isRotationModeActive;
        }
    }, [isRotationModeActive, enterFullscreenMode, exitFullscreenMode, exitThumbMode]);

    useEffect(() => {
        const printerSize = material && material.printer_size;

        modelViewer.current = new ModelViewerUtil(visualizer, {
            darkModeOn: isDarkModeActive,
            debug: true,
            objects: {
                [ModelViewerObjects.Axes]: {
                    draw: getFeatureFlags(contentCustomization, [FF.Viewer.AxesShown, FF.Viewer.AxesBtnShown]).some(
                        Boolean,
                    ),
                    active: getFeatureFlag(contentCustomization, FF.Viewer.AxesShown),
                },
                [ModelViewerObjects.Grid]: {
                    draw: getFeatureFlags(contentCustomization, [
                        FF.Viewer.GridFloorBtnShown,
                        FF.Viewer.GridFloorShown,
                    ]).some(Boolean),
                    active: getFeatureFlag(contentCustomization, FF.Viewer.GridFloorShown),
                },
                [ModelViewerObjects.ModelBoundingBox]: {
                    draw: getFeatureFlags(contentCustomization, [
                        FF.Viewer.ModelBoxShown,
                        FF.Viewer.ModelBoxBtnShown,
                    ]).some(Boolean),
                    active: getFeatureFlag(contentCustomization, FF.Viewer.ModelBoxShown),
                },
                [ModelViewerObjects.PrinterBoundingBox]: {
                    draw: getFeatureFlags(contentCustomization, [
                        FF.Viewer.PrinterBoxShown,
                        FF.Viewer.PrinterBoxBtnShown,
                    ]).some(Boolean),
                    active: getFeatureFlag(contentCustomization, FF.Viewer.PrinterBoxShown),
                    // size: '50-23-50',
                    size: printerSize,
                },
                [ModelViewerObjects.ModelEdges]: {
                    color: getObjectFeatureValue(contentCustomization, ObjectsFeature.ModelEdgesColor),
                },
                [ModelViewerObjects.Model]: {
                    color: getObjectFeatureValue(contentCustomization, ObjectsFeature.ModelColor),
                },
            },
            customBackgroundColor: getObjectFeatureValue(contentCustomization, ObjectsFeature.SceneBackgroundColor),
        });

        return () => {
            modelViewer.current?.clear();
            modelViewer.current = undefined;
            dispatch(viewerActions.setModelIsRendered(false));
            model.setIsModelRendered(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // update printer box
    useEffect(() => {
        const printerSize = material && material.printer_size;
        if (modelViewer.current && printerSize) {
            const printerBoundingBox = modelViewer.current.objects[ModelViewerObjects.PrinterBoundingBox];
            printerBoundingBox?.object?.updateSize(printerSize);
            modelViewer.current.requestRenderIfNotRequested();
        }
    }, [material]);

    useEffect(() => {
        if (
            !allowToRenderViewer ||
            !modelViewer.current ||
            !onLoadFileCallback ||
            modelId === lastRenderedModelId.current
        )
            return;

        const originalFileExtension = `.${getFileExtensionByUrl(object_model_original_url || title)}`;

        const { promise, cancel } = modelViewer.current.loadModel(file_model_viewer_url, {
            enableEdgesRendering: ModelExtensionsByFormat.step.includes(originalFileExtension),
            enableBaseMaterial: !ModelExtensionsByFormat['3mf'].includes(originalFileExtension),
        });

        promise.then(modelFileUrl => {
            lastRenderedModelId.current = modelId;
            onLoadFileCallback(modelFileUrl);
            dispatch(viewerActions.setModelIsRendered(true));
            model.setIsModelRendered(true);
        });

        return () => {
            cancel();
        };
    }, [
        dispatch,
        model,
        modelId,
        onLoadFileCallback,
        correctObjectModelId,
        allowToRenderViewer,
        file_model_viewer_url,
        object_model_original_url,
        title,
    ]);

    useEffect(() => {
        if (modelViewer.current && lastRenderedModelId.current) {
            modelViewer.current.highlightWallsThinOn = { on: showThinWalls, wallThinFaces: thinModelFaces };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showThinWalls]);

    return (
        <div
            className={cn([
                styles.box,
                { [styles.expanded]: isFullscreenModeActive, 'viewer-dark-mode-on': isDarkModeActive },
            ])}
            onKeyDown={useCloseOnEscape(toggleFullscreenMode, !isFullscreenModeActive)}
            tabIndex={-1}
        >
            <ModelViewerFullscreenTopPanel
                show={isFullscreenModeActive && !isRotationModeActive}
                toggleFullscreenMode={toggleFullscreenMode}
                contentCustomization={contentCustomization}
                setRenderMode={setCurrentMode}
                modelViewer={modelViewer.current}
                title={title}
                isThumbModeOn={isThumbModeOn} // TODO change to children
                className="small-margin-bottom"
            />

            <div
                className={styles.inner}
                ref={ref => {
                    visualizer = ref as HTMLDivElement;
                }}
            >
                <ModelViewerDarkModeBox
                    show={
                        isFullscreenModeActive && currentMode === ModelViewerRenderMode.Solid && !isRotationModeActive
                    }
                    isDarkModeActive={isDarkModeActive}
                    toggleDarkMode={toggleDarkMode}
                    modelViewer={modelViewer.current}
                />
                <ModelViewerSceneControlsPanel
                    show={
                        isFullscreenModeActive && currentMode === ModelViewerRenderMode.Solid && !isRotationModeActive
                    }
                    contentCustomization={contentCustomization}
                    modelViewer={modelViewer.current}
                />
                <ViewerThumb model={model} show={isThumbModeOn} expanded={isFullscreenModeActive} />

                <ModelViewerRotationDescription show={isRotationModeActive} />

                <ViewerModePanel
                    expanded={isFullscreenModeActive}
                    toggleFullscreenMode={toggleFullscreenMode}
                    toggleThumbMode={toggleThumbMode}
                    enterRotationMode={enterRotationMode}
                    isRotationActive={isRotationModeActive}
                />
            </div>

            <ModelViewerFullscreenBottomPanel
                show={isRotationModeActive}
                modelId={modelId}
                contentCustomization={contentCustomization}
                modelViewer={modelViewer.current}
                className="small-margin-top"
                exitRotationMode={exitRotationMode}
            />
        </div>
    );
}, viewerPropsAreEqual);

import { buildCreateSlice, asyncThunkCreator, createAsyncThunk } from '@reduxjs/toolkit';
import {
    AppService,
    AuthService,
    ClientService,
    CompanyService,
    ModelsService,
    OrderService,
    PaymentService,
} from '@services';
import { AppSession } from '@utils';
// import type { FetchBaseQueryError } from '@services/df/baseApi';
import type { RootState, AppDispatch, AppExtraApi } from '@types';

export const appExtraApi = {
    AppSession,
    AppService,
    AuthService,
    ClientService,
    CompanyService,
    ModelsService,
    OrderService,
    PaymentService,
};

// Use throughout your app instead of plain 'createSlice', 'createAsyncThunk'
export const createAppSlice = buildCreateSlice({
    creators: { asyncThunk: asyncThunkCreator },
});

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
    state: RootState;
    dispatch: AppDispatch;
    // rejectValue: FetchBaseQueryError;
    extra: AppExtraApi;
}>();

export type LanguageList = { [key: string]: string }[];

export const LANGUAGES_FALLBACK: LanguageList = [
    { ar: 'العربية' },
    { de: 'Deutsch' },
    { en: 'English' },
    { 'en-au': 'English (AU)' },
    { fr: 'Français' },
    { hu: 'Magyar' },
    { nl: 'Nederlands' },
    { ru: 'Русский' },
    { cs: 'Čeština' },
    { it: 'Italiano' },
    { es: 'Español' },
    { 'zh-hk': '香港中文' },
    { 'zh-hans': '簡體中文' },
    { 'zh-tw': '繁體中文' },
    { ko: '한국어' },
    { pl: 'Polski' },
    { sl: 'Slovenski' },
    { hr: 'Hrvatski' },
    { th: 'ภาษาไทย' },
    { uk: 'Українська' },
    { da: 'Dansk' },
    { tr: 'Türkçe' },
    { sv: 'Svenska' },
];

export const FallbackLng = 'en';

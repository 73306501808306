import cn from 'classnames';
import React, { useState, useCallback, useMemo, useRef, useEffect, RefObject, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Button } from '@react-md/button';
import { Typography } from '@react-md/typography';
import { Tooltipped } from '@react-md/tooltip';
import { Product as ProductType } from '@types';
import { Preloader } from '@components/preloader';
import { ProductDrawings } from '@components/product-drawings';
import { Icons } from '@components/icons';
import { CountInput, CountInputProps } from '@components/count-input';
import { Label } from '@components/label';
import { Fade } from '@components/animations';
import { ContentKeys as FC, FeatureContent, FeatureMarkup, useFeatureContent } from '@components/feature-content';
import { FeatureOn, FeatureToggle, FeatureKeys as FF } from '@components/feature-flags';
import { selectAppSettings } from '@modules/app';
import { selectParentModelById, ModelCell } from '@modules/models';
import { PurchaseSpecification, productActions, selectDrawingsByProductId } from '@modules/product';
import { makeSelectOrderExactTriggersTbd } from '@modules/order';
import { useUpdateProduct } from '@pages/widget/order/hooks';
import { getNodeIdComposer } from '@utils';
import { Price } from '@components/price';
import { TbdLabel } from '@components/tbd';
import { Prefix } from './constants';

import styles from './order-products.module.scss';

const _id = getNodeIdComposer(Prefix, 'product');
const contentKey = _id('markup_row');

export interface ProductProps extends React.HTMLAttributes<HTMLElement> {
    product: ProductType;
    delay: number;
    in?: boolean; // TODO
    isSubmitted: boolean;
}

export const OrderProduct = ({ product, in: show, isSubmitted, ...rest }: ProductProps) => {
    const dispatch = useAppDispatch();
    const { is_drawings_optional_for_non_processable_file_formats: nonCadDrawingsOptional } =
        useAppSelector(selectAppSettings);
    const model = useAppSelector(state => selectParentModelById(state, product.model_id));
    const selectOrderExactTriggersTbd = useMemo(makeSelectOrderExactTriggersTbd, []);
    const triggersTbd = useAppSelector(state => selectOrderExactTriggersTbd(state, product.id));

    const deprecatedCustomContent = useFeatureContent(FC.OrderPage.UnderModelContentSet);
    const customContent = useFeatureContent(contentKey) || deprecatedCustomContent;

    const drawings = useAppSelector(state => selectDrawingsByProductId(state, product.id));
    const [isRemoving, setIsRemoving] = useState(false);

    const onDelete = useCallback(
        (product: ProductType) => () => {
            setIsRemoving(true);
            dispatch(productActions.remove(product));
        },
        [dispatch, setIsRemoving],
    );

    const update = useUpdateProduct();
    const [count, setCount] = useState(product.count);
    const handleCountChange: CountInputProps['onChange'] = useCallback(
        value => {
            setCount(value);
            update(product.id, value);
        },
        [product.id, setCount, update],
    );

    const allowedDrawings = product.accept_drawing;
    const requiredDrawings = model && !model.is_processable && nonCadDrawingsOptional ? false : product.require_drawing;
    const absentRequiredDrawings = requiredDrawings && !drawings?.length;
    const errorProduct = isSubmitted && absentRequiredDrawings;

    const { t } = useTranslation();

    return (
        <React.Fragment key={product.id}>
            <Fade
                as="tr"
                in={show}
                className={cn(styles.firstRow, { [styles.errorRow]: errorProduct })}
                mountOnEnter
                unmountOnExit
                appear
                {...rest}
            >
                <td className={styles.cell}>
                    <ModelCell label={product.model_title} thumb={product.model_thumbnail} />
                </td>

                <td className={styles.cell}>
                    <Typography type="body-2" component={'span'}>
                        {product.material_display_name}
                    </Typography>
                </td>
                <td colSpan={4} className={styles.cell}>
                    {product.spec_string ? <PurchaseSpecification fields={product.config_formatted_json} /> : '-'}
                </td>
                <td className={styles.cell} align="center">
                    {/*todo useDebounce count*/}
                    <CountInput
                        id={product.id}
                        value={count}
                        precision={0}
                        min={1}
                        max={65000}
                        className="inline-flex"
                        onChange={handleCountChange}
                    />
                </td>

                <FeatureToggle flagKey={FF.OrderPage.ProductBatchDiscountShown}>
                    <FeatureOn>
                        <td className={styles.cell} align="center">
                            <Typography type="body-2" component={'div'}>
                                {`${product.discount_percents}%`}
                            </Typography>
                        </td>
                    </FeatureOn>
                </FeatureToggle>

                <td className={styles.cell} align="center">
                    {triggersTbd.length ? (
                        <TbdLabel />
                    ) : (
                        <Typography type="body-2" component={'div'}>
                            <Price>{product.price_per_part}</Price>
                        </Typography>
                    )}
                </td>

                <td className={styles.cell} align="center">
                    {triggersTbd.length ? (
                        <TbdLabel />
                    ) : (
                        <Typography type="body-2" component={'div'}>
                            <Price>{product.total_price}</Price>
                        </Typography>
                    )}
                </td>

                <td className={styles.delete}>
                    {isRemoving ? (
                        <Preloader size={20} stroke={2} className="rmd-typography--hint" />
                    ) : (
                        <Tooltipped
                            id={_id('delete', product.id)}
                            tooltip={t('buttons.delete', 'Delete')}
                            position="above"
                            lineWrap={false}
                            spacing={10}
                            disableAutoSpacing
                        >
                            <Button
                                buttonType="icon"
                                theme="error"
                                // theme="clear"
                                themeType="flat"
                                className="rmd-button--icon-circle"
                                onClick={onDelete(product)}
                                aria-label={t('buttons.delete', 'Delete')}
                            >
                                <Icons.Trash />
                            </Button>
                        </Tooltipped>
                    )}
                </td>
            </Fade>

            {allowedDrawings && (
                <Fade
                    as="tr"
                    in={show}
                    className={cn('relative', styles.optionalRow, {
                        [styles.errorRow]: errorProduct,
                        error_has_no_drawing: errorProduct,
                        [styles.lastRow]: !customContent,
                    })}
                    mountOnEnter
                    unmountOnExit
                    appear
                    {...rest}
                >
                    <td colSpan={11} className={styles.drawings}>
                        {drawings?.length ? (
                            <Label id={_id('drawings_title')}>
                                <FeatureContent
                                    contentKey={_id('drawings_title')}
                                    fallback={t('subtitles.drawings', 'Technical drawings')}
                                />
                            </Label>
                        ) : requiredDrawings ? (
                            <Label
                                className={cn(styles.required, {
                                    'rmd-typography--theme-warning': requiredDrawings,
                                    'rmd-typography--theme-error': errorProduct,
                                })}
                            >
                                {t('errors.requiredDrawings', 'Attach at least one drawing')}
                            </Label>
                        ) : null}

                        <ProductDrawings productId={product.id} drawings={drawings} />
                    </td>
                </Fade>
            )}

            <FeatureMarkup
                contentKey={contentKey}
                wrapper={props => (
                    <Fade
                        id={props.id}
                        as="tr"
                        in={show}
                        className={cn(styles.optionalRow, {
                            [styles.errorRow]: errorProduct,
                        })}
                        mountOnEnter
                        unmountOnExit
                        appear
                        {...rest}
                    >
                        <td colSpan={11}>{props.children}</td>
                    </Fade>
                )}
            />
        </React.Fragment>
    );
};

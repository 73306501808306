import cn from 'classnames';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@react-md/typography';
import { Select } from '@react-md/form';
// import { BELOW_RIGHT_ANCHOR } from '@react-md/utils';
import { CountInput } from '@components/count-input';
import { FeatureContent } from '@components/feature-content';
import { FeatureKeys as FF, useFeatureFlag } from '@components/feature-flags';
import { Label } from '@components/label';
import { SpecificationFeaturesProps, CncFeaturesListItem } from './types';
import { priceConfigId } from '../helpers';
import { cutSelectListIfTooLong, cutSelectListIfTooLongOnKeydown } from '@utils/widget/cut-select-list-if-too-long';

import styles from './specification-features.module.scss';

function composeFeatureTitle(hole: CncFeaturesListItem) {
    // todo translation? ObjectModelCncFeature['shape_type_display']
    const title = `${hole.shape_type ? hole.shape_type : 'general'} ${hole.through === 'through' ? hole.through : ''}`;
    return title[0].toUpperCase() + title.toLowerCase().slice(1);
}

export const SpecificationFeatures: React.FC<SpecificationFeaturesProps> = ({
    fieldName,
    data,
    onChange,
    priceConfigValue,
}) => {
    const totalColumnShown = useFeatureFlag(FF.CartPage.CncFeaturesTotalColumnShown);

    const handleChange = (currentIndex: number, field: string, currentValue: string | number) => {
        const newVal = priceConfigValue.map((item, index) => {
            if (index === currentIndex) {
                return { ...item, [field]: currentValue };
            }
            return item;
        });

        onChange(fieldName, newVal);
    };

    const { t } = useTranslation();

    return (
        <div className="overflow-x-auto">
            <table className={styles.table}>
                <thead className={styles.head}>
                    <tr>
                        <th className={styles.cell}>
                            <Label className={styles.label}>
                                <FeatureContent
                                    contentKey={priceConfigId(fieldName, 'hole_type')}
                                    fallback={t('labels.holeType', 'Hole type')}
                                />
                            </Label>
                        </th>
                        <th className={styles.cell}>
                            <Label className={styles.label}>
                                <FeatureContent
                                    contentKey={priceConfigId(fieldName, 'measurements')}
                                    fallback={`'⌀, ${t('measurements.mm', 'mm')}'`}
                                />
                            </Label>
                        </th>
                        {totalColumnShown && (
                            <th className={styles.cell}>
                                <Label className={styles.label}>
                                    <FeatureContent
                                        contentKey={priceConfigId(fieldName, 'total')}
                                        fallback={t('labels.total', 'Total')}
                                    />
                                </Label>
                            </th>
                        )}
                        <th className={styles.cell}>
                            <Label className={styles.label}>
                                <FeatureContent
                                    contentKey={priceConfigId(fieldName, 'quantity')}
                                    fallback={t('labels.quantity', 'Quantity')}
                                />
                            </Label>
                        </th>
                        <th className={styles.cell}>
                            <Label className={styles.label}>
                                <FeatureContent
                                    contentKey={priceConfigId(fieldName, 'fit_thread')}
                                    fallback={t('labels.fitThread', 'Fit / thread')}
                                />
                            </Label>
                        </th>
                    </tr>
                </thead>
                <tbody className={styles.body}>
                    {data.map((hole, index) => {
                        const disabled = hole.threads.length < 2;
                        const currentThreadId = priceConfigValue[index].thread;
                        const currentQuantity = priceConfigValue[index].quantity;
                        const currentThread = hole.threads.find(thread => thread.id === currentThreadId);
                        const hasError = currentThread?.isTooDeep && currentQuantity !== 0;

                        return (
                            <Fragment key={index}>
                                <tr className={cn({ [styles['hole-error']]: hasError })}>
                                    <td className={styles.cell}>
                                        <Typography type="body-2" component={'div'}>
                                            {composeFeatureTitle(hole)}
                                        </Typography>
                                    </td>
                                    <td className={styles.cell}>
                                        <Typography type="body-2" component={'div'}>
                                            {hole.diameter}
                                        </Typography>
                                    </td>
                                    {totalColumnShown && (
                                        <td className={styles.cell}>
                                            <Typography type="body-2" component={'div'}>
                                                {hole.quantity}
                                            </Typography>
                                        </td>
                                    )}

                                    <td className={styles.cell}>
                                        <CountInput
                                            id={priceConfigId(fieldName, 'quantity_field', index)}
                                            precision={0}
                                            min={0}
                                            max={999}
                                            value={currentQuantity}
                                            className="rmd-text-field-container--small"
                                            onChange={newCount => {
                                                handleChange(index, 'quantity', newCount);
                                            }}
                                            controls={false}
                                            inline
                                        />
                                    </td>

                                    <td className={styles.cell}>
                                        <Select
                                            id={priceConfigId(fieldName, 'thread_field', index)}
                                            className={styles.dropdown}
                                            onChange={value => handleChange(index, 'thread', value)}
                                            options={hole.threads.map(thread => ({
                                                label: thread.name,
                                                value: thread.id,
                                            }))}
                                            disabled={disabled}
                                            value={currentThreadId}
                                            rightChildren={disabled ? null : undefined} // undefined means displaying default value
                                            // anchor={BELOW_RIGHT_ANCHOR}
                                            listboxClassName={cn('rmd-listbox--small', styles.listbox)}
                                            listboxWidth="min"
                                            inline
                                            closeOnResize
                                            closeOnScroll
                                            onClick={cutSelectListIfTooLong}
                                            onKeyDown={event => cutSelectListIfTooLongOnKeydown(event.key)}
                                        />
                                    </td>
                                </tr>
                                <tr className={styles['label-error']}>
                                    {hasError && (
                                        <td className={styles.error} colSpan={5}>
                                            {
                                                <FeatureContent
                                                    contentKey={priceConfigId(fieldName, 'error')}
                                                    fallback={t('errors.holeIsTooDeep', 'The hole is too deep')}
                                                />
                                            }
                                        </td>
                                    )}
                                </tr>
                            </Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

import { AxiosError } from 'axios';
import { concat, from, of } from 'rxjs';
import { catchError, filter, finalize, switchMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { AppEpic, CreateClientFormErrors, InvalidateLevel } from '@types';
import { ClientService } from '@services';
import { formatResponseErrors } from '@utils';
import { appActions } from '../app';
import { clientActions } from './slice';
import { selectClientId } from './selectors';

const createClientEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(clientActions.create.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const clientId = selectClientId(state);
            const { form, onSuccess, onError } = action.payload;

            return from(ClientService.init().createClient(form)).pipe(
                switchMap(({ data }) => {
                    return concat(
                        clientId
                            ? [
                                  appActions.invalidateStore({
                                      purge: InvalidateLevel.Order,
                                  }),
                                  clientActions.createSuccess(data),
                              ]
                            : [clientActions.createSuccess(data)],
                    ).pipe(finalize(() => onSuccess && onSuccess()));
                }),
                catchError((error: AxiosError<CreateClientFormErrors>) => {
                    const errors = formatResponseErrors<CreateClientFormErrors>(
                        error?.response?.data || ({} as CreateClientFormErrors),
                    );

                    return of(clientActions.createFailure(errors)).pipe(finalize(() => onError && onError(errors)));
                }),
            );
        }),
    );

const loadClientEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(clientActions.load.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            return from(ClientService.init().loadClient(action.payload)).pipe(
                switchMap(({ data }) => of(clientActions.loadSuccess(data))),
                catchError(error => of(clientActions.loadFailure())),
            );
        }),
    );

export const clientEpics = combineEpics(createClientEpic, loadClientEpic);

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PriceConfigFields } from '@constants';
import { ObjectModel, PreselecitonConfig, Preselection, TechnologyMaterial, TechnologyMaterialOption } from '@types';
import { getSpecificationInitialValues, getSpecificationOptions } from './helpers';

type PriceConfigField = (typeof PriceConfigFields)[number];

export function useSpecificationFieldsLabels() {
    const { t } = useTranslation();

    const pickedFieldsTitles: Record<PriceConfigField, string> = {
        color: t('labels.color', 'Color'),
        filling: t('labels.filling', 'Filling'),
        layer_thickness: t('labels.layerThickness', 'Layer thickness'),
        lead_time: t('labels.leadTime', 'Priority'),
        thickness: t('labels.thickness', 'Thickness'),
        cnc_features_types: t('subtitles.cncHoles', 'Holes'),
        tolerance: t('labels.tolerance', 'Tolerance'),
        post_production: t('subtitles.postProduction', 'Post production'),
        extra_fieldsets: '',
    };

    return pickedFieldsTitles;
}

interface SpecificationForm {
    model: ObjectModel;
    material?: TechnologyMaterial;
    initialThickness?: string;
}

// todo rewrite as redux selector?
export function useSpecificationForm({ model, material, initialThickness }: SpecificationForm) {
    return useMemo(() => {
        const formParams = getSpecificationOptions(model, material);
        const defaultValues = getSpecificationInitialValues({ formParams, initialThickness });
        return { formParams, defaultValues };
    }, [model, material, initialThickness]);
}

import React, { useCallback, useEffect } from 'react';
import { useAppDispatch } from '@app/hooks';
import { useQueryParams, NumberParam, StringParam, withDefault } from 'use-query-params';
import { SearchField } from '@components/search-field';
import { clientsActions } from '@modules/clients';
import { getNodeIdComposer } from '@utils';
import { GetParams } from '@constants';
import { FiltersId } from './constants';

const _id = getNodeIdComposer(FiltersId);

export const ClientsSearch = () => {
    const dispatch = useAppDispatch();
    const [query, setQuery] = useQueryParams({
        [GetParams.PaginationPage]: withDefault(NumberParam, 1),
        [GetParams.Search]: withDefault(StringParam, ''),
    });

    useEffect(() => {
        dispatch(clientsActions.load(query));
    }, [dispatch, query]);

    const handleFinalizeSearch = useCallback(
        search => {
            setQuery({
                search,
                page: 1,
            });
        },
        [setQuery],
    );

    return (
        <SearchField
            id={_id('search', 'field')}
            value={query.search}
            onFinalize={handleFinalizeSearch}
            style={{ marginTop: '20px' }}
        />
    );
};

import cn from 'classnames';
import React from 'react';
import { Typography } from '@react-md/typography';
import { ClientRepresentationSerializer } from '@types';

import styles from './client-selection.module.scss';

interface ClientRowProps extends ClientRepresentationSerializer {
    clientId: number | undefined;
    setClient: (clientId: number) => void;
}

export const ClientRow = ({
    id,
    email,
    name,
    surname,
    phone_number,
    company_name,
    clientId,
    setClient,
}: ClientRowProps) => {
    const _fullName = [name, surname].filter(Boolean);
    const fullName = _fullName.length ? _fullName.join(' ') : '';

    return (
        <tr className={cn(styles.row, { [styles.current]: id === clientId })} onClick={() => setClient(id!)}>
            <td className={styles.cell}>
                <Typography type="body-2" component="span" className={styles.value}>
                    {fullName}
                </Typography>
            </td>
            <td className={styles.cell}>
                <Typography type="body-2" component="span" className={styles.value}>
                    {phone_number}
                </Typography>
            </td>
            <td className={styles.cell}>
                <Typography type="body-2" component="span" className={styles.value}>
                    {email}
                </Typography>
            </td>
            <td className={styles.cell}>
                <Typography type="body-2" component="span" className={cn(styles.value, 'rmd-typography--right')}>
                    {company_name}
                </Typography>
            </td>
        </tr>
    );
};

import pick from 'lodash/pick';
import {
    PriceConfig,
    PriceConfigForm,
    PriceConfigFormPostProduction,
    PriceConfigOption,
    Product,
    Technology,
    TechnologyMaterial,
} from '@types';

function getProductSpecificationConfig(config: PriceConfig, material: TechnologyMaterial) {
    return Object.entries(config).reduce((acc, [key, value]) => {
        if (key === 'extra_fieldsets') {
            const options = value as PriceConfig['extra_fieldsets'];

            const defaultValues = options.map(val => {
                return {
                    fieldset: val.fieldset,
                    choice: val.choice,
                };
            });
            return { ...acc, [key]: defaultValues };
        }

        if (key === 'features') {
            const features = value as PriceConfig['features'];

            if (!features?.length) {
                return acc;
            }

            return {
                ...acc,
                features: features.map(item => pick(item, ['type', 'diameter', 'quantity', 'thread'])),
            };
        }

        if (key === 'post_production') {
            const post_production = value as PriceConfig['post_production'];
            if (!post_production?.length) {
                return acc;
            }

            return {
                ...acc,
                [key]: post_production.map(pp => {
                    const result: PriceConfigFormPostProduction = {
                        uuid: pp.uuid,
                        quantity: pp.value.quantity,
                    };

                    if (pp.chosen_color) {
                        // we can obtain color uuid only this way
                        const material_pp = material.post_production.find(_pp => _pp.uuid === pp.uuid);
                        const color = material_pp?.colors!.find(color => color.color === pp.chosen_color!.color);
                        result.option_uuid = color!.uuid as string;
                    }

                    return result;
                }),
            };
        }

        return { ...acc, [key]: (value as PriceConfigOption).uuid };
    }, {} as PriceConfigForm);
}

export function getProductsSpecifications(products: Product[], technologies: Technology[]) {
    return products.reduce((acc, product) => {
        let material: TechnologyMaterial | undefined = undefined;
        const technology = technologies.find(technology => {
            const _material = technology.materials.find(material => {
                return material.id === product.material_id;
            });
            material = _material;
            return _material;
        });

        if (!technology) {
            if (process.env.NODE_ENV !== 'production') {
                throw new Error(`No technology found for material with id: ${product.material_id}`);
            }

            return acc;
        }

        if (!material) {
            if (process.env.NODE_ENV !== 'production') {
                throw new Error(`No material found for technology with id: ${technology.id}`);
            }

            return acc;
        }

        const technologyId = technology!.id;
        const config = getProductSpecificationConfig(product.config, material);

        return {
            ...acc,
            [product.id]: {
                technologyId,
                materialIds: { [technologyId]: product.material_id },
                priceConfigs: { [product.material_id]: config },
            },
        };
    }, {});
}
